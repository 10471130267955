import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const TimeM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M15 3.5v1a.5.5 0 01-.5.5H13l.001 2.071a6.965 6.965 0 013.191 1.323l1.111-1.112a.501.501 0 01.638-.057l.07.057.707.708a.5.5 0 01.057.637l-.057.07-1.112 1.11A7 7 0 1111 7.07V5H9.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zM12 9a5 5 0 10.001 10.001A5 5 0 0012 9zm1 2.5v4a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"
      />
    </Svg>
  )
}

export default TimeM
