import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import BackwardLink from 'components/BackwardLink'
import { headerClasses } from 'pages/UserSettings/styles'
import { SimpleLoading } from 'components/LoadingIcon'
import { refreshHighSecTriggers } from 'pages/UserSettings/constants'
import { classes } from './styles'
import NoReminderBanner from './components/NoReminderBanner'
import ReminderList from './components/ReminderList'
import useTenantReminderList from './useTenantReminderList'
import AddReminderButton from './components/AddReminderButton'
import AddEditDrawer from './components/AddEditDrawer'
import ResetToDefaultButton from './components/ResetToDefaultButton'

const TenantReminderListPageContent: React.FC = () => {
  const { tenantUuid, workflowType } = useParams<{ tenantUuid: string; workflowType: string }>()
  const history = useHistory()
  const { t } = useFavurTranslation()
  const {
    loading,
    titleKey,
    reminderRow,
    reminderAmount,
    drawerProps,
    onClickAdd,
    onClickEdit,
    onResetToDefault,
  } = useTenantReminderList(tenantUuid, workflowType)

  const gotoBackLink = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Page header={<BackwardLink onClickIcon={gotoBackLink} title={t(titleKey)} />} sxHeader={headerClasses.subHeader}>
      {loading ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.sectionsContainer} {...refreshHighSecTriggers}>
          {reminderAmount === 0 ? (
            <NoReminderBanner />
          ) : (
            <ReminderList reminderRow={reminderRow} onClickEdit={onClickEdit} />
          )}
          {reminderAmount < 3 && <AddReminderButton onClick={onClickAdd} />}
          {reminderAmount === 0 && <ResetToDefaultButton onClick={onResetToDefault} />}
          <AddEditDrawer {...drawerProps} />
        </Box>
      )}
    </Page>
  )
}

export default TenantReminderListPageContent
