import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const TasksL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M23 6c.5 0 1 .4 1 .9V7l-2 2V8H6v16h16v-5l2-2v8c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1V7c0-.6.4-1 1-1h18zm6.7.3c.4.4.4 1 0 1.3v.1L16 21.4l-6.7-6.7a1 1 0 0 1 1.3-1.5h.1l5.3 5.4L28.3 6.3a1 1 0 0 1 1.4 0z"
      />
    </Svg>
  )
}

export default TasksL
