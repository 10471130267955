import React, { Dispatch, SetStateAction, useState } from 'react'
import { Box, Button, SxProps, Theme, useMediaQuery } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import InfoDialog from 'components/Forms/components/InfoDialog'
import { getFormattedFromDate } from 'utils/date'
import { absenceRequestDateFormat } from 'utils/constants'
import { TenantNamesT } from 'types'
import theme from 'utils/theme'
import ShowBalancesButton from './ShowBalancesButton'
import NewForm from './NewForm'
import { FormErrorsT, FormFieldsT } from './useNewAbsenceRequestForm'
import { newAbsenceRequestClasses } from './styles'
import NewAbsenceRequestAlertList from '../components/NewAbsenceRequestAlertList'

interface INewContentProps {
  showDialogBlockedPeriod: boolean
  setShowDialogBlockedPeriod: Dispatch<SetStateAction<boolean>>
  handlePersonUuidChange: (personUuid: string) => void
  getTenantOptions: () => Record<string, string>
  selectedPersonTenant: TenantNamesT | null
  firstAvailableDate: Date | null
  classicPersonIds: string[]
  loadingMutation: boolean
  formFields: FormFieldsT
  formErrors: FormErrorsT
  setValue: (field: string, value: string | Date | null) => void
  onSubmit: () => Promise<void>
  fullScreen?: boolean
  drawerSx?: SxProps<Theme>
  onClickConflict?: () => void
  showAbsencePlannerPreview?: boolean
}

const NewContent: React.FC<INewContentProps> = ({
  showDialogBlockedPeriod,
  setShowDialogBlockedPeriod,
  selectedPersonTenant,
  firstAvailableDate,
  classicPersonIds,
  getTenantOptions,
  handlePersonUuidChange,
  formFields,
  formErrors,
  setValue,
  onSubmit,
  fullScreen = true,
  drawerSx,
  onClickConflict,
  showAbsencePlannerPreview,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [disabledNewRequest, setDisabledNewRequest] = useState(false)
  const { t, locale } = useFavurTranslation()
  const {
    personUuid: { value: selectedPersonUuid },
    fromDate: { value: fromDate },
    toDate: { value: toDate },
  } = formFields

  const startDate = fromDate ? getFormattedFromDate(fromDate as Date, 'yyyy-MM-dd') : undefined
  const endDate = toDate ? getFormattedFromDate(toDate as Date, 'yyyy-MM-dd') : startDate

  return (
    <Box>
      <Box sx={fullScreen && isSmallScreen ? newAbsenceRequestClasses.formContainer : []}>
        <NewForm
          personIds={classicPersonIds}
          getTenantOptions={getTenantOptions}
          handlePersonUuidChange={handlePersonUuidChange}
          formFields={formFields}
          formErrors={formErrors}
          setValue={setValue}
          onSideBar={!fullScreen && !isSmallScreen}
        />
        <NewAbsenceRequestAlertList
          startDate={startDate}
          endDate={endDate}
          onClickConflict={onClickConflict}
          selectedPersonUuid={selectedPersonUuid as string}
          showAbsencePlannerPreview={showAbsencePlannerPreview}
          onLoadRestrictingEvents={(events) => {
            const areThereEvents = events && events.length > 0
            setDisabledNewRequest(areThereEvents)
          }}
        />
      </Box>
      <ActionCardButtons fullScreen={fullScreen || isSmallScreen}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onSubmit()}
          data-testid="absence-request_new_request"
          disabled={disabledNewRequest}
        >
          {t('absenceRequest.new.sendRequest')}
        </Button>
        <ShowBalancesButton selectedPersonUuid={selectedPersonUuid as string} drawerSx={drawerSx} />
      </ActionCardButtons>
      {showDialogBlockedPeriod && (
        <InfoDialog
          open
          title={t('label.notice!')}
          action={() => setShowDialogBlockedPeriod(false)}
          text={t('absenceRequest.new.blockedPeriod', {
            tenantName: selectedPersonTenant?.tenantName,
            blockedDays: selectedPersonTenant?.tenantAbsencesBlockingPeriod,
            firstDate: firstAvailableDate
              ? getFormattedFromDate(firstAvailableDate as Date, absenceRequestDateFormat, locale)
              : '',
          })}
        />
      )}
    </Box>
  )
}

export default NewContent
