import { useMutation } from '@apollo/client'
import { EventsOfTask } from 'pages/Tasks/types'
import { MutationResult } from 'types'
import { performEventToTask } from '../queries'

const usePerformEventToTask = (favurUuid: string) => {
  const [performEventToTaskFunction, { loading }] = useMutation<{ performEventToTask: MutationResult }>(
    performEventToTask,
  )

  const sendReminderToFrontline = () => {
    return performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.sendReminderToFrontline,
      },
    })
  }

  const sendReminderToManager = () => {
    return performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.sendReminderToManager,
      },
    })
  }

  return {
    loading,
    sendReminderToFrontline,
    sendReminderToManager,
  }
}

export default usePerformEventToTask
