import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const DataProtectionS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8.35 2.05l.1.04 4.6 1.87c.42.17.7.58.7 1.04 0 5.19-3.26 8.08-5.31 8.91-.28.12-.6.12-.88 0C4.99 12.87 2.25 9.65 2.25 5c0-.43.25-.81.63-1l.08-.04 4.6-1.87c.25-.1.53-.12.79-.04zM8 4a2 2 0 0 0-2 1.85V7h-.5a.5.5 0 0 0-.5.41v3.09c0 .24.18.45.41.5h5.09a.5.5 0 0 0 .5-.41V7.5a.5.5 0 0 0-.41-.5H10V6a2 2 0 0 0-2-2zm0 4a.5.5 0 0 1 .42.77l-.06.08-.04.04L8.5 10h-1l.19-1.11-.05-.04-.06-.08A.5.5 0 0 1 8 8zm0-3a1 1 0 0 1 1 .88V7H7V5.88A1 1 0 0 1 8 5z"
      />
    </Svg>
  )
}

export default DataProtectionS
