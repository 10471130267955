import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const MonatsblattL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M21 4h.15a2 2 0 0 1 1.84 1.84L23 6v3h-2V6h-7v8H6v12h5v2H5.85a2 2 0 0 1-1.84-1.84L4 26V11.59L11.59 4H21zm0 7a1 1 0 0 1 .12 2H20v2.08c.9.14 1.75.46 2.5.94l1.55-1.56-.26-.25a1 1 0 0 1 1.33-1.5l.09.08 2 2a1 1 0 0 1-1.33 1.5l-.09-.08-.33-.33-1.47 1.46a6.5 6.5 0 1 1-6-2.27l.01-.07v-2h-1a1 1 0 0 1-.12-2H21zm-2 6a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9zm-1.38 2.21l.09.08 2 2a1 1 0 0 1-1.33 1.5l-.09-.08-2-2a1 1 0 0 1 1.33-1.5zM12 6.41L6.41 12H12V6.42z"
      />
    </Svg>
  )
}

export default MonatsblattL
