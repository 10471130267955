import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const PastS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 2a6 6 0 1 1-3.77 10.67.58.58 0 0 1-.04-.86l.27-.27c.21-.21.54-.23.77-.05a4.45 4.45 0 1 0-.28-6.73l1.23 1.22c.24.25.07.66-.28.66H2.4A.39.39 0 0 1 2 6.26V2.74c0-.34.42-.52.66-.27l1.2 1.2A5.98 5.98 0 0 1 8 2zm.2 2.9c.28 0 .52.21.57.49v2.62l.99.76c.22.18.29.49.16.73l-.06.09-.24.3a.58.58 0 0 1-.72.16L8.8 10 7.24 8.77V5.48c0-.29.2-.52.48-.57h.48z"
      />
    </Svg>
  )
}

export default PastS
