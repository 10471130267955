import { useCallback, useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useSessionContext from 'hooks/useSessionContext'
import { CmsContentT, CmsPaginatedContentT } from 'types'
import { ContentTypeT, EventTimelineT } from 'types/cms'
import { listPaginatedcontents } from 'shared/queries'

const DEFAULT_PAGE_SIZE = 10

type PaginatedContentsProps = {
  skip?: boolean
  contentType: ContentTypeT
  pageSize?: number
  eventTimelineFilter?: EventTimelineT
  date?: string
  startDate?: string
  endDate?: string
  isAbsenceRestricted?: boolean
  showAdmin?: boolean
  onLoad?: (_contents: CmsContentT[]) => void
}

const appendContent = (newEntries: CmsContentT[], currentEntries: CmsContentT[]) => {
  return newEntries.reduce((acc, newContent) => {
    const existingContent = acc.find((content) => content.uuid === newContent.uuid)
    if (!existingContent) return [...acc, newContent]
    return acc
  }, currentEntries)
}

const updateContent = (currentEntries: CmsContentT[], newEntries: CmsContentT[] | undefined, paginate: boolean) => {
  if (paginate && newEntries) {
    return appendContent(newEntries, currentEntries)
  }
  return newEntries ?? []
}

const useListCmsPaginatedContents = ({
  skip = false,
  contentType,
  pageSize = DEFAULT_PAGE_SIZE,
  eventTimelineFilter,
  date,
  startDate,
  endDate,
  isAbsenceRestricted,
  showAdmin = true,
  onLoad,
}: PaginatedContentsProps) => {
  const { cmsActivated } = useSessionContext()
  const [paginate, setPaginate] = useState(false)
  const [page, setPage] = useState(1)
  const [contents, setContents] = useState<CmsContentT[]>([])
  const { data: contentData, loading: loading, error: error } = useJamesApolloQuery<{
    cmsPaginatedContentList: CmsPaginatedContentT
  }>(listPaginatedcontents, {
    variables: {
      contentType: contentType,
      page: page,
      pageSize: pageSize,
      eventTimeline: eventTimelineFilter,
      date: date,
      startDate: startDate,
      endDate: endDate,
      isAbsenceRestricted: isAbsenceRestricted,
      showAdmin: showAdmin,
    },
    fetchPolicy: 'cache-and-network',
    skip: !cmsActivated || skip,
    onCompleted: (result) => {
      const newEntries = result.cmsPaginatedContentList.entries
      const updatedContent = updateContent(contents, newEntries, paginate)
      setContents(updatedContent)
      setPaginate(false)
      onLoad && onLoad(updatedContent)
    },
  })

  const loadMore = useCallback(() => {
    setPaginate(true)
    setPage(page + 1)
  }, [page])

  const totalCount = contentData?.cmsPaginatedContentList.totalEntries || 0

  const hasMoreElements = Boolean(totalCount && contents && totalCount > contents.length)

  return {
    list: contents,
    loadMore,
    hasMoreElements,
    totalEntries: totalCount,
    loading,
    error,
  }
}

export default useListCmsPaginatedContents
