import React, { useCallback, useContext } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FlashMessagesContext from 'services/FlashMessages/context'
import usePerformEventToTask from 'pages/Tasks/hooks/usePerformEventToTask'
import { errorCodes } from 'pages/Tasks/constants'
import { getFirstErrorCode } from 'utils/apollo'
import { classes } from './styles'
import { PlusContext } from './Plus'

interface ISendReminderProps {
  taskUuid: string
  onAction: () => void
}

const SendReminder: React.FC<ISendReminderProps> = ({ taskUuid, onAction }) => {
  const { t } = useFavurTranslation()
  const { setIsOpenDrawer } = useContext(PlusContext)
  const { sendReminderToManager } = usePerformEventToTask(taskUuid)

  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)

  const submit = useCallback(() => {
    removeAll()

    sendReminderToManager()
      .then((_result) => {
        setIsOpenDrawer(false)
        onAction()
      })
      .catch((e) => {
        setIsOpenDrawer(false)
        const code = getFirstErrorCode(e)

        if (errorCodes.includes(code)) setFlashMessage(t(code))
        else setFlashMessage(t('common.error.be.default'))
      })
  }, [onAction, removeAll, sendReminderToManager, setFlashMessage, setIsOpenDrawer, t])

  return (
    <Button
      variant="contained"
      sx={classes.regularButton}
      onClick={submit}
      data-testid="absence-request_action-send_reminder"
    >
      {t('absenceRequest.buttons.sendReminder')}
    </Button>
  )
}

export default SendReminder
