import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_XL } from './constants'

const LanguageXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M40 7c1.054 0 1.918.816 1.995 1.85L42 9v22a2.001 2.001 0 01-1.85 1.995L40 33H27.739l-8.914 7.64a.5.5 0 01-.817-.289l-.008-.09V33H8a2.001 2.001 0 01-1.995-1.85L6 31V9c0-1.054.816-1.918 1.85-1.995L8 7h32zm0 2H8v22h12v6l7-6h13V9zM26.855 23.097C26.387 25.977 25.284 28 24 28c-1.242 0-2.316-1.894-2.808-4.627l-.047-.276h5.71zm4.526 0a8.028 8.028 0 01-5.097 4.568c.734-1.018 1.256-2.516 1.553-4.204l.06-.364h3.484zm-11.275 0c.284 1.835.826 3.477 1.613 4.568a8.017 8.017 0 01-4.981-4.308l-.115-.26h3.483zm6.881-5.162a20.164 20.164 0 01.045 3.629l-.045.5H21.01a20.115 20.115 0 01-.046-3.628l.046-.5h5.977zm4.736 0c.174.662.274 1.349.274 2.065 0 .597-.07 1.173-.195 1.732l-.083.333h-3.696c.067-.678.106-1.371.106-2.065 0-.52-.022-1.04-.061-1.554l-.045-.51h3.7zm-11.746 0A21.053 21.053 0 0019.871 20c0 .52.022 1.04.06 1.554l.043.51h-3.697A8.056 8.056 0 0116 20c0-.597.072-1.173.196-1.732l.081-.333h3.7zM24 12c1.242 0 2.316 1.894 2.808 4.627l.047.276h-5.71C21.613 14.023 22.716 12 24 12zm2.28.335a8.016 8.016 0 014.982 4.308l.115.26h-3.483c-.284-1.835-.826-3.477-1.613-4.568zm-4.564 0c-.734 1.018-1.256 2.516-1.55 4.204l-.06.364h-3.483a8.012 8.012 0 015.093-4.568z"
      />
    </Svg>
  )
}

export default LanguageXL
