import React, { useCallback, useMemo, useState } from 'react'
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { LANGUAGES } from 'constants/i18n'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useUserLanguage from 'hooks/useUserLanguage'
import UserSettingsSectionEditCTA from 'pages/UserSettings/components/SectionEditCTA'
import palette from 'utils/theme/palette'

import { LanguageSettingM } from 'icons'
import { classes } from './styles'

const Language: React.FC = () => {
  const { t, language } = useFavurTranslation()
  const { setUserLanguage, loadingMutation } = useUserLanguage()

  const [open, setOpen] = useState(false)

  const languageSelected = useMemo(() => {
    return t(LANGUAGES.find((lng) => lng.code === language)?.label ?? '')
  }, [t, language])

  const setLanguage = useCallback(
    (event: SelectChangeEvent<string>) => {
      setUserLanguage(event.target.value as string)
      setOpen(false)
    },
    [setUserLanguage],
  )

  return (
    <Box sx={classes.containerLanguage}>
      <UserSettingsSectionEditCTA
        title={t('page.userSettings.language.label')}
        subtitle={languageSelected}
        onClick={() => {
          if (!loadingMutation) {
            setOpen(true)
          }
        }}
        icon={<LanguageSettingM fill={palette.primary.main} />}
        data-testid="user-settings-profile-change-language"
      />

      <Select
        sx={classes.selectMenu}
        displayEmpty
        value={language}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        onChange={setLanguage}
      >
        {LANGUAGES.map((lng) => (
          <MenuItem key={lng.code} value={lng.code}>
            {t(lng.label)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default Language
