import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const CheckMarkCircleS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13ZM10.9133 5.78139C11.0687 5.55314 11.0097 5.24211 10.7814 5.08669C10.5531 4.93128 10.2421 4.99033 10.0867 5.21858L6.8881 9.91633L5.92742 8.33367C5.78413 8.09761 5.47661 8.02241 5.24055 8.16569C5.0045 8.30898 4.92929 8.6165 5.07258 8.85256L6.09099 10.5303C6.16647 10.6593 6.27135 10.7732 6.40223 10.8573C6.53565 10.943 6.69056 10.9938 6.85366 10.9995C7.01685 11.0051 7.17539 10.9651 7.3149 10.8877C7.45212 10.8116 7.56444 10.7033 7.64752 10.5778L10.9133 5.78139Z"
        fill={fill}
      />
    </Svg>
  )
}

export default CheckMarkCircleS
