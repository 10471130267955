import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import routes from 'services/RoutesProvider/routes'
import Alert from 'components/Alert'
import { formatDateRange } from 'pages/Cms/EventsList/EventCard/utils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CmsContentT } from 'types'
import { classes } from './styles'

interface IEventRestrictingAbsencesListProps {
  events: CmsContentT[]
}

const EventRestrictingAbsencesList: React.FC<IEventRestrictingAbsencesListProps> = ({ events }) => {
  const { t, locale } = useFavurTranslation()
  const history = useHistory()

  return (
    <Box sx={classes.container}>
      {events?.map((event) => (
        <Alert
          key={event.uuid}
          title={`${t('absenceRequest.new.absenceBlockerAlert.title', {
            dateRange: formatDateRange({
              startDate: event.startDate,
              endDate: event.endDate,
              isWholeDay: event.isWholeDay,
              t,
              locale,
            }),
          })}`}
          severity="warning"
          content={t('absenceRequest.new.absenceBlockerAlert.text')}
          isWholeClickable
          onClick={() => history.push(`${routes.cmsContentView}/${event.uuid}`)}
        />
      ))}
    </Box>
  )
}

export default EventRestrictingAbsencesList
