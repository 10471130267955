/* eslint-disable camelcase */
import React from 'react'
import { gql } from '@apollo/client'
import { Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Dialog from 'components/Dialog'
import info from 'info.json'
import routes from 'services/RoutesProvider/routes'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import styles from './styles'

interface IApplicationInformationProps {
  label: string
  onClose: () => void
}

const getNumberOfCommits = (infoData: {
  commit?: string
  build_date?: string
  version?: string
  number_commits?: string
}) => {
  return infoData.number_commits ? ` (${infoData.number_commits})` : ''
}

const ApplicationInformation: React.FC<IApplicationInformationProps> = ({ label, onClose }) => {
  const { t } = useFavurTranslation()
  const { data } = useJamesApolloQuery<{ info: { applicationEnv: string } }>(
    gql`
      query {
        info {
          applicationEnv
        }
      }
    `,
  )

  return (
    <Dialog
      open
      onClose={onClose}
      title={label}
      actions={
        <Button onClick={onClose} color="secondary" size="large">
          {t('common.dialog.button.close')}
        </Button>
      }
    >
      <Table sx={styles.table}>
        <TableBody>
          <TableRow sx={styles.row}>
            <TableCell>
              <Typography>Build Version</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{`${info.version}${getNumberOfCommits(info)}`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={styles.row}>
            <TableCell>
              <Typography>Built</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{info.build_date}</Typography>
            </TableCell>
          </TableRow>

          {['LIVE', 'LOCAL', 'STAGING'].includes(data?.info?.applicationEnv as string) ? (
            <TableRow sx={styles.row}>
              <TableCell>
                <Typography>Features</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  <Button
                    type="submit"
                    size="small"
                    variant="text"
                    color="primary"
                    component={Link}
                    to={routes.settings}
                    sx={data?.info?.applicationEnv === 'LIVE' ? styles.invisibleLink : {}}
                  >
                    settings
                  </Button>
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </Dialog>
  )
}

export default ApplicationInformation
