import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useRolesViews from 'hooks/useRolesViews'
import Page from 'components/Page'
import routes from 'services/RoutesProvider/routes'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { SimpleLoading } from 'components/LoadingIcon'
import { MonthlySheetTaskT, TaskT } from 'pages/Tasks/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFilterUtils from 'components/Filter/useFilterUtils'
import { headerClasses } from 'pages/Tasks/styles'
import BackwardLink from 'components/BackwardLink'
import useDismissTaskNotifications from 'pages/Tasks/hooks/useDismissTaskNotifications'
import { taskQueryMonlthySheet } from '../../queries'
import Content from './Content'

const refTaskUuidParamName = 'ref'

interface INewMonthlySheetPageProps {
  taskUuid: string
}

const DetailsMonthlySheet: React.FC<INewMonthlySheetPageProps> = ({ taskUuid }) => {
  const { t } = useFavurTranslation()
  const { activeView, roles } = useRolesViews()
  const { setLoadFilterTasks } = useFilterUtils()
  const isOffice = activeView === roles.office
  const history = useHistory()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const refTaskUuid = urlParams.get(refTaskUuidParamName)
  const { loading: dismissLoading } = useDismissTaskNotifications({ taskUuid, isManager: isOffice })

  const gotoBackLink = useCallback(() => {
    setLoadFilterTasks()
    history.goBack()
  }, [history, setLoadFilterTasks])

  const { loading, data, refetch } = useJamesApolloQuery<{ task: TaskT<MonthlySheetTaskT> }>(taskQueryMonlthySheet, {
    variables: {
      office: isOffice,
      favurUuid: taskUuid,
    },
    fetchPolicy: 'no-cache',
    onError() {
      history.push(routes.dashboard)
    },
  })

  if (loading || dismissLoading) {
    return (
      <Page
        header={<BackwardLink onClickIcon={gotoBackLink} title={t('workTimeControl.title')} />}
        hideNativeNavigation
        hideBackground
        columnCenter
        hideHamburger
        sxHeader={headerClasses.subHeader}
      >
        <SimpleLoading />
      </Page>
    )
  }

  if (!data?.task) {
    return (
      <Page
        header={<BackwardLink onClickIcon={gotoBackLink} title={t('workTimeControl.title')} />}
        hideNativeNavigation
        hideBackground
        columnCenter
        hideHamburger
        sxHeader={headerClasses.subHeader}
      ></Page>
    )
  }

  return (
    <Content
      isOffice={isOffice}
      task={data?.task as TaskT<MonthlySheetTaskT>}
      onClickBackIcon={gotoBackLink}
      title={t(refTaskUuid ? 'worktimecontrol.titleRelated' : 'workTimeControl.title')}
      refetch={() => {
        refetch()
      }}
    />
  )
}

export default DetailsMonthlySheet
