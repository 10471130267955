import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { MutationResult, useMutation } from '@apollo/client'
import { Typography, Button, Box, TextField } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import FlashMessagesContext from 'services/FlashMessages/context'
import { SimpleLoading } from 'components/LoadingIcon'
import Success, { defaultSuccessPageTimeout } from 'components/Success'
import routes from 'services/RoutesProvider/routes'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { FF_SHARE_USER_DATA_COMMENT_FRONTLINER } from 'constants/featureFlags'
import useStateBackLink from 'hooks/useStateBackLink'
import { userDataFormClasses as classes } from './styles'
import { shareUserDataStatus } from '../constants'
import { taskStates } from '../../constants'
import { sendUserDataMutation } from '../../mutations'
import { TaskT, UserDataUrlParamsT, ShareUserDataTaskT } from '../../types'
import { taskQueryByUuid } from '../../queries'
import { ShareUserDataFormContext } from '../Details'

const Sharing: React.FC<{ goToStep: (stepName: string) => void }> = () => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [showSuccess, setShowSuccess] = useState(false)
  const [userComment, setUserComment] = useState('')
  const showShareUserDataUserComment = useFeatureFlag(FF_SHARE_USER_DATA_COMMENT_FRONTLINER)
  const { favurUuid } = useParams<UserDataUrlParamsT>()
  const { data: taskData, loading } = useJamesApolloQuery<{ task: TaskT<ShareUserDataTaskT> }>(
    taskQueryByUuid(favurUuid),
  )
  const { setShowForm, refetch } = useContext(ShareUserDataFormContext)

  const addShareUserDataUserComment = useCallback(() => {
    if (!showShareUserDataUserComment) {
      return {}
    }

    return { userComment }
  }, [showShareUserDataUserComment, userComment])

  const [sendUserData, { loading: mutationLoading }] = useMutation<MutationResult>(sendUserDataMutation)

  const onSubmit = useCallback(() => {
    removeAll()

    const variables = {
      favurUuid,
      status: shareUserDataStatus.reviewOfficeUnseen,
      ...addShareUserDataUserComment(),
    }

    sendUserData({ variables })
      .then(() => setShowSuccess(true))
      .catch((_e) => setFlashMessage('common.error.be.default'))
  }, [addShareUserDataUserComment, favurUuid, removeAll, sendUserData, setFlashMessage])

  useEffect(() => {
    if (!showSuccess) return undefined
    const successTimeout = setTimeout(() => {
      if (!refetch) {
        pushBackLinkOrDefault(`${routes.tasks}/${taskStates.todo}`)
      } else {
        setShowForm(false)
        refetch()
      }
    }, defaultSuccessPageTimeout)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, pushBackLinkOrDefault, favurUuid, refetch, setShowForm])

  if (loading || !taskData || mutationLoading) {
    return <SimpleLoading />
  }

  return showSuccess ? (
    <Success translationKey="component.forms.sharing.success" dataTestId="user-data-sharing-success" />
  ) : (
    <CenteredBox sx={classes.containerFlex}>
      <Box>
        <>
          <Typography variant="h2" sx={classes.heading}>
            {t('component.forms.sharing.title')}
          </Typography>
          <Typography variant="h2" sx={classes.headingSecondLine}>
            {taskData.task.tenantName}
          </Typography>
          <Typography variant="body1">{t('component.forms.sharing.content')}</Typography>
          {showShareUserDataUserComment && (
            <Box sx={classes.commentFieldContainer}>
              <TextField
                variant="standard"
                value={userComment}
                placeholder={t('personalData.sendShareUserDataPage.comment.title')}
                fullWidth
                multiline
                sx={classes.commentField}
                onChange={(e) => setUserComment(e.target.value)}
                helperText={t('personalData.sendShareUserDataPage.comment.helperText')}
              />
            </Box>
          )}
        </>
      </Box>
      <Box display="flex" flexDirection="column">
        <Button variant="contained" color="secondary" onClick={onSubmit} sx={classes.callToAction}>
          {t('component.forms.sharing.button')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default Sharing
