import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const CalendarAppointmentM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M10.85 14h-2.7c-.08 0-.15-.34-.15-.75v-1.5c0-.41.07-.75.15-.75h2.7c.08 0 .15.34.15.75v1.5c0 .41-.07.75-.15.75zM18 21H6a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h2V3.5c0-.28.22-.5.5-.5h1c.28 0 .5.22.5.5V5h4V3.5c0-.28.22-.5.5-.5h1c.28 0 .5.22.5.5V5h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zm0-3v-8a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1z"
      />
    </Svg>
  )
}

export default CalendarAppointmentM
