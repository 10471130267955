import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const HourM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12 2c5.524 0 10 4.476 10 10s-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2zm0 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm.5 2a.5.5 0 01.5.5v4.964l2.139 1.427a.5.5 0 01.138.693l-.554.832a.5.5 0 01-.694.139L11 12.535V6.5a.5.5 0 01.5-.5h1z"
      />
    </Svg>
  )
}

export default HourM
