import React from 'react'
import { SimpleLoading } from 'components/LoadingIcon'
import { CmsContentT } from 'types'
import { Box } from '@mui/material'
import useGetCmsContent from 'pages/Cms/View/useGetCmsContent'
import ContentView from 'pages/Cms/View/ContentView'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { EventDetailsProps } from './types'
import EventHeader from './EventHeader'

const EventDetails: React.FC<EventDetailsProps> = ({ eventId }) => {
  const { t } = useFavurTranslation()
  const { content, loading } = useGetCmsContent({ contentUuid: eventId })
  const { setSelectedEvent } = useSelectedStatesActionsContext()

  if (loading) {
    return <SimpleLoading />
  }

  return (
    <Box>
      <EventHeader title={t('absencePlanner.taskbar.eventDetails.title')} onClick={() => setSelectedEvent(null)} />
      <ContentView content={content as CmsContentT} />
    </Box>
  )
}

export default EventDetails
