import React, { useState } from 'react'
import { Button } from '@mui/material'
import useFilterUtils from 'components/Filter/useFilterUtils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ConfirmDeleteDialog from 'pages/Tasks/components/ConfirmDeleteDialog'
import { MonthlySheetTaskT, TaskT } from 'pages/Tasks/types'
import routes from 'services/RoutesProvider/routes'
import useStateBackLink from 'hooks/useStateBackLink'

interface IDeleteWorkflowProps {
  task: TaskT<MonthlySheetTaskT>
}

const DeleteWorkflow: React.FC<IDeleteWorkflowProps> = ({ task }) => {
  const { t } = useFavurTranslation()
  const { setLoadFilterTasks } = useFilterUtils()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { pushBackLinkOrDefault } = useStateBackLink()
  const reload = () => {
    setLoadFilterTasks()
    pushBackLinkOrDefault(routes.tasks)
  }
  return (
    <>
      <Button variant="text" onClick={() => setDeleteDialogOpen(true)} color="error">
        {t('workTimeControl.buttons.deleteWorkflow')}
      </Button>
      {deleteDialogOpen && (
        <ConfirmDeleteDialog onClose={() => setDeleteDialogOpen(false)} task={task} onDeleteSuccess={reload} />
      )}
    </>
  )
}

export default DeleteWorkflow
