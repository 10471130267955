import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ArrowDownThinM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M20.78 7.22c.3.3.3.77 0 1.06l-7.86 7.84a1.3 1.3 0 0 1-1.84 0L3.22 8.28a.75.75 0 1 1 1.06-1.06l7.72 7.7 7.72-7.7c.3-.3.77-.3 1.06 0Z"
      />
    </Svg>
  )
}

export default ArrowDownThinM
