import { useEffect, useState } from 'react'
import { UserAnnouncementT } from 'types'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { useLocation } from 'react-router'
import { userAnnouncementsQuery } from 'shared/queries'
import useSessionContext from 'hooks/useSessionContext'
import { AuthenticationKind } from 'constants/authentication'

export const useUserAnnouncements = () => {
  const { pathname } = useLocation()
  const { auth } = useSessionContext()

  const [userAnnouncements, setUserAnnouncements] = useState<UserAnnouncementT[]>([])
  const [routeAnnouncement, setRouteAnnouncement] = useState<UserAnnouncementT>()
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  const { refetch } = useJamesApolloQuery<{ userAnnouncements: UserAnnouncementT[] }>(userAnnouncementsQuery, {
    onCompleted: (data) => {
      setUserAnnouncements(data.userAnnouncements)
    },
    skip: auth < AuthenticationKind.AUTHENTICATED,
  })

  useEffect(() => {
    if (userAnnouncements.length > 0) {
      // Refetch for when we close the drawer without calling onClose (when navigating through link)
      if (showAnnouncement) {
        refetch()
      }
      const routeAnnouncements = userAnnouncements.filter(({ announcement }) => announcement.route === pathname)
      setRouteAnnouncement(routeAnnouncements[0])
      setShowAnnouncement(routeAnnouncements.length > 0)
    }
    // Needed to ignore unnecessary showAnnouncement updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, userAnnouncements])

  return { routeAnnouncement, showAnnouncement, setShowAnnouncement, refetch }
}
