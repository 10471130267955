import React, { useMemo } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightThinS, EventM } from 'icons'
import { getMetadata } from 'pages/Cms/helper'
import { formatTitle, formatDateRange } from 'pages/Cms/EventsList/EventCard/utils'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { styles } from './styles'
import { EventCardProps } from '../types'

const EventCard: React.FC<EventCardProps> = ({ content }) => {
  const { language, t, locale } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])
  const { setSelectedEvent } = useSelectedStatesActionsContext()

  if (!metadata) return null

  return (
    <Box sx={styles.contentCardLink}>
      <Card variant="outlined" sx={styles.contentCard} onClick={() => setSelectedEvent(content?.uuid ?? null)}>
        <Box sx={styles.iconText}>
          <EventM />
          <Box sx={styles.texts}>
            <Typography variant="body2" sx={styles.dateRangeText}>
              {formatDateRange({ ...content, locale, t })}
            </Typography>
            <Typography variant="body1" sx={styles.titleText}>
              {formatTitle(metadata.title ?? '')}
            </Typography>
          </Box>
        </Box>
        <ArrowRightThinS />
      </Card>
    </Box>
  )
}

export default EventCard
