import React, { useContext } from 'react'
import { TimelineItemContentFormT } from 'components/Timeline/types'
import { FormL } from 'icons'
import theme from 'utils/theme'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ShareUserDataFormContext } from 'pages/Tasks/ShareUserData/Details'
import TimelineCTA from '../TimelineCTA'

const TimeLineItemForm: React.FC<TimelineItemContentFormT> = ({ tenantName, clickable }) => {
  const { t } = useFavurTranslation()
  const { setShowForm } = useContext(ShareUserDataFormContext)
  const handleClick = () => setShowForm(true)

  return (
    <TimelineCTA
      onClick={clickable ? handleClick : undefined}
      icon={<FormL stroke={theme.palette.primary.main} />}
      subtitle={tenantName}
      title={t('shareUserData.timeline.formCta')}
    />
  )
}

export default TimeLineItemForm
