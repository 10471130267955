import React, { useMemo } from 'react'
import { MeObject } from 'types'
import Page from 'components/Page'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { headerClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import PersonalDataForm from './PersonalDataForm'
import { personalDataQuery } from './queries'

const PersonalDataPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { pushOriginLinkOrDefault } = useStateBackLink()

  const { data, loading } = useJamesApolloQuery<{ me: MeObject }>(personalDataQuery)

  const dataObject = useMemo(() => {
    if (loading || !data?.me) return undefined
    const { phoneNumber: phone, firstName, lastName, birthDate } = data.me.user
    return { phone, firstName, lastName, birthDate }
  }, [data?.me, loading])

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushOriginLinkOrDefault(routes.userSettings)
          }}
          title={t('page.userSettings.personalData.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
      noPadding
      hideNativeNavigation
    >
      {!dataObject ? <SimpleLoading /> : <PersonalDataForm {...dataObject} />}
    </Page>
  )
}

export default PersonalDataPage
