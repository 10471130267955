import { shiftFields } from 'shared/queries'
import { gql } from '@apollo/client'
import type { QueryDates } from 'types'

const shiftsFavurPart = (dates: QueryDates, personIds: number[] | undefined): string => {
  return `
    shiftsFavurByDate(startDate: "${dates.start}", endDate: "${dates.end}", personIds: [${personIds}], types: ["shift", "absence"]) {
      date
      tenants {
        tenantName
        tenantId
        costCenterUuid
        costCenterName
        ownerId
        ${shiftFields}
      }
  }`
}

export const shiftListQuery = (dates: QueryDates, personIds: number[] | undefined) => {
  return gql`query allShiftsFavur{
      isMultiTenant{isMultiTenant},
      ${shiftsFavurPart(dates, personIds)}
    }`
}

export const shiftFavurByDateQuery = (dates: QueryDates, personIds: number[] | undefined) => {
  return gql`query{
    ${shiftsFavurPart(dates, personIds)}
  }`
}

export const effectiveShiftListQuery = (dates: QueryDates) => {
  return gql`query allEffectiveShiftsFavur{
      effectiveShiftsFavur(startDate: "${dates.start}", endDate: "${dates.end}") {
          date
          tenants {
            tenantName      
            tenantId
            costCenterUuid
            costCenterName
            hideUnverifiedEffectiveTimes
            shifts {
              from
              until
              timeType
              validated
            }
          }
      }
      isMultiTenant{isMultiTenant}
    }`
}
