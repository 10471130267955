import React, { createContext, Context, useReducer, useContext, useEffect } from 'react'

import { actions as checkListActions, reducers as checkListReducers } from 'components/Filter/Page/CheckList/reducer'
import { actions as pageActions, reducers as pageReducers } from 'components/Filter/Page/reducer'
import { actions as chipActions, reducers as chipReducers, updateChips } from 'components/Filter/ChipList/reducer'
import { actions as dateRangeActions, reducers as dateRangeReducers } from 'components/Filter/Page/DateRange/reducer'
import { actions as personsListAction, reducers as personsListReducers } from 'components/Filter/Page/Persons/reducer'
import { isEqual } from 'lodash/fp'
import {
  ACTION,
  ActionDispatchT,
  ChipDataT,
  FilterPageStateT,
  FilterStateT,
  FilterStateValueT,
  FilterType,
  OptionT,
  ReducerActionT,
} from './types'
import useFilterUtils from './useFilterUtils'

export type FilterContextT = {
  cancelPersonsList: (filterName: string) => void
  changeValue: (filterName: string, value: string) => void
  clearPersonList: (filterName: string) => void
  clearPersonListElement: (filterName: string, personId: number) => void
  clearValue: (filterName: string) => void
  closeFilterPage: () => void
  confirmPersonsList: (filterName: string, selectedPersons: number[]) => void
  getChips: () => ChipDataT[]
  getConfiguration: () => OptionT[]
  getFilters: () => FilterStateT
  getName: () => string
  getPersonsListSelected: () => string | undefined
  getScrollTo: () => string | undefined
  getValue: (filterName: string) => FilterStateValueT | null
  isCheckedOption: (filterName: string, optionName: string) => boolean
  isFilterPageOpen: () => boolean
  openFilterPage: () => void
  openPersonsList: (filterName: string) => void
  removeChip: (chip: ChipDataT) => void
  resetFilterPage: () => void
  setFilters: (filters: FilterStateT) => void
  setScrollTo: (destination: string | undefined) => void
  state: FilterPageStateT
  submitFilterPage: () => void
  toggleOption: (filterName: string, optionName: string) => void
  validateFilters: () => boolean
}

const emptyState: FilterPageStateT = {
  name: '',
  configuration: [],
  chips: [],
  dirtyFilters: {},
  displayFilterPage: false,
  filters: {},
  personsListSelected: undefined,
  taskCounts: undefined,
  scrollTo: undefined,
}

export const createInitialState = (values?: Partial<FilterPageStateT>): FilterPageStateT => {
  return { ...emptyState, ...values }
}

export const initialContext: FilterContextT = {
  cancelPersonsList: (_filterName: string) => {},
  changeValue: (_filtername: string, _optionName: string) => {},
  clearPersonList: (_filterName: string) => {},
  clearPersonListElement: (_filterName: string, _personId: number) => {},
  clearValue: (_filtername: string) => {},
  closeFilterPage: () => {},
  confirmPersonsList: (_filterName: string, _selectedPersons: number[]) => {},
  getChips: () => [],
  getConfiguration: () => [],
  getFilters: () => ({}),
  getName: () => '',
  getPersonsListSelected: () => undefined,
  getScrollTo: () => undefined,
  getValue: (_filterName: string) => null,
  isCheckedOption: (_filterName: string, _optionName: string) => false,
  isFilterPageOpen: () => false,
  openFilterPage: () => {},
  openPersonsList: (_filterName: string) => {},
  removeChip: (_chip: ChipDataT) => {},
  resetFilterPage: () => {},
  setFilters: (_filters: FilterStateT) => {},
  setScrollTo: (_destination: string | undefined) => {},
  state: createInitialState(),
  submitFilterPage: () => {},
  toggleOption: (_filterName: string, _optionName: string) => {},
  validateFilters: () => false,
}

export const FilterContext: Context<FilterContextT> = createContext(initialContext)

export const createProviderValue = (state: FilterPageStateT, dispatch: React.Dispatch<ReducerActionT>) => {
  return {
    state,
    getConfiguration: () => state.configuration,
    getFilters: () => state.filters,
    getName: () => state.name,
    setFilters: (filters: FilterStateT) => {
      state.filters = filters
      dispatch({ type: ACTION.UPDATE_CHIPS })
    },
    ...checkListActions(state, dispatch),
    ...dateRangeActions(state, dispatch),
    ...pageActions(state, dispatch),
    ...chipActions(state, dispatch),
    ...personsListAction(state, dispatch),
  }
}

export const filterReducer = (state: FilterPageStateT, action: ReducerActionT): FilterPageStateT => {
  const reducers: ActionDispatchT = {
    ...checkListReducers,
    ...dateRangeReducers,
    ...chipReducers,
    ...pageReducers,
    ...personsListReducers,
  }
  const reducer = reducers[action.type]
  if (!reducer) {
    return state
  }
  return reducer(state, action)
}

export const getDefaultFilters = (configuration: OptionT[], useInitialValue: boolean) =>
  configuration.reduce(
    (defaultFilters, config) =>
      config.type === FilterType.MULTIPLE_CHOICE && (config.defaultValues || config.initialValues)
        ? {
            ...defaultFilters,
            [config.name]: (useInitialValue ? config.initialValues : config.defaultValues) || [],
          }
        : defaultFilters,
    {},
  )

export const FilterProvider: React.FC<{
  configuration: OptionT[]
  name?: string
  children: React.ReactNode
}> = ({ children, configuration, name }) => {
  const { clearStoredFilters, getStoredFilters } = useFilterUtils()
  const storedFilters = getStoredFilters(name)
  const filters = storedFilters ?? getDefaultFilters(configuration, true)
  const chips = updateChips(filters, configuration)
  const initialState = createInitialState({ configuration, filters, chips, name })
  const [filterState, dispatch] = useReducer(filterReducer, initialState)

  useEffect(() => {
    if (storedFilters) {
      clearStoredFilters(name as string)
    }
  }, [clearStoredFilters, name, storedFilters])

  useEffect(() => {
    if (!isEqual(filterState.configuration, initialState.configuration)) {
      dispatch({ type: ACTION.UPDATE_CONFIGURATION_OPTIONS, value: { options: initialState.configuration } })
      dispatch({ type: ACTION.UPDATE_CHIPS })
    }
  }, [filterState.configuration, initialState.configuration])

  return <FilterContext.Provider value={createProviderValue(filterState, dispatch)}>{children}</FilterContext.Provider>
}

const useFilterContext = () => {
  const { storeState } = useFilterUtils()
  const context = useContext(FilterContext)
  const { getName, getFilters } = context
  return {
    storeState: () => storeState(getName(), getFilters()),
    ...context,
  }
}

export default useFilterContext
