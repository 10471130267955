import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Box } from '@mui/material'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { ArrowLeftThinM } from 'icons'
import theme from 'utils/theme'
import { styles } from './styles'
import SmallScreenPageHeader from '../SmallScreenPageHeader'
import Events from '.'

const EventsPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { selectedEvent } = useSelectedStatesContext()

  return (
    <Box sx={styles.mobilePage}>
      {!selectedEvent && (
        <SmallScreenPageHeader
          title={t('absencePlanner.taskbar.events.title')}
          icon={<ArrowLeftThinM fill={theme.palette.primary.main} />}
        />
      )}
      <Events />
    </Box>
  )
}

export default EventsPage
