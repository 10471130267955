import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import BaseInputDrawer from 'components/BaseDrawer/BaseInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import WheelPicker from 'components/WheelPicker'
import WheelPickerColumn from 'components/WheelPicker/WheelPickerColumn'
import Dialog from 'components/Dialog'
import { throttledRefreshHighSecSession } from 'utils/throttledRefreshHighSecSession'
import { DeleteTrashRemoveM, InfoM } from 'icons'
import { classes } from './styles'
import ExplanationDrawer from '../ExplanationDrawer'

export interface IAddEditDrawerProps {
  open: boolean
  onClose: () => void
  value: number
  onChange: (days: number, hours: number) => void
  onSuccess: (forcedValue?: number) => void
  successButtonDisabled: boolean
  title: string
  showDeleteButton: boolean
}

const AddEditDrawer: React.FC<IAddEditDrawerProps> = ({
  open,
  onClose,
  value,
  onChange,
  onSuccess,
  successButtonDisabled,
  title,
  showDeleteButton,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showExplanationDrawer, setShowExplanationDrawer] = useState(false)
  const { t } = useFavurTranslation()

  const daysOptions = useMemo(
    () =>
      [...Array(31).keys()].map((option) => ({
        value: option,
        label: t('page.userSettings.tenantReminderList.nDays', { days: option }),
      })),
    [t],
  )

  const hoursOptions = useMemo(
    () =>
      [...Array(24).keys()].map((option) => ({
        value: option,
        label: t('page.userSettings.tenantReminderList.nHours', { hours: option }),
      })),
    [t],
  )

  const { days, hours } = useMemo(() => ({ days: Math.floor(value / 24), hours: value % 24 }), [value])

  const onDelete = useCallback(() => {
    onSuccess(0)
    setShowConfirmDialog(false)
  }, [onSuccess])

  const deleteActions = (
    <Box>
      <Button variant="text" onClick={() => setShowConfirmDialog(false)} color="secondary">
        {t('common.cancel')}
      </Button>
      <Button variant="text" onClick={() => onDelete()} color="secondary" data-testid="delete-task">
        {t('tasks.delete.accept')}
      </Button>
    </Box>
  )

  return (
    <>
      <BaseInputDrawer
        open={open}
        onClose={onClose}
        onSuccess={onSuccess}
        successButtonText={t('page.userSettings.personalData.actions.save')}
        paperSx={classes.paper}
        successButtonDisabled={successButtonDisabled || value === 0}
      >
        <Box sx={classes.content}>
          <Box sx={classes.textContainer}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body1" sx={classes.textBody}>
              {t('page.userSettings.tenantReminderList.editBody')}
            </Typography>
            <Button
              color="primary"
              variant="text"
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setShowExplanationDrawer(true)
                throttledRefreshHighSecSession()
              }}
              sx={classes.moreInfoButton}
            >
              {t('page.userSettings.tenantReminderList.moreInfo')}
              <InfoM />
            </Button>
          </Box>
          <WheelPicker>
            <WheelPickerColumn
              options={daysOptions}
              value={days}
              onChange={(changeValue) => onChange(changeValue, hours)}
            />
            <WheelPickerColumn
              options={hoursOptions}
              value={hours}
              onChange={(changeValue) => onChange(days, changeValue)}
            />
          </WheelPicker>
          {showDeleteButton && (
            <Button
              color="error"
              variant="text"
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={() => setShowConfirmDialog(true)}
            >
              <Box sx={classes.innerDeleteButton}>
                <DeleteTrashRemoveM /> {t('page.userSettings.tenantReminderList.delete')}
              </Box>
            </Button>
          )}
        </Box>
      </BaseInputDrawer>
      <ExplanationDrawer open={showExplanationDrawer} onClose={() => setShowExplanationDrawer(false)} />
      <Dialog
        open={showConfirmDialog}
        actions={deleteActions}
        title={t('page.userSettings.tenantReminderList.deleteDialogTitle')}
      >
        <Typography variant="body2">{t('page.userSettings.tenantReminderList.deleteDialogBody')}</Typography>
      </Dialog>
    </>
  )
}

export default AddEditDrawer
