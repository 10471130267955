import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const DocumentsLockedL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M18.41 3L26 10.59V27a2 2 0 0 1-1.85 2H8a2 2 0 0 1-2-1.85V5a2 2 0 0 1 1.85-2h10.56zM16 5H8v22h16V14a1 1 0 0 0-1-1h-6a1 1 0 0 1-1-1V5zm-2 10a2.74 2.74 0 0 1 2.64 2.47l.02.17v1.65h.79l.12.04a.7.7 0 0 1 .4.44l.02.1.01.13v4.29l-.01.12-.03.13a.7.7 0 0 1-.41.42l-.1.03-.12.01h-6.66l-.12-.01-.12-.03a.7.7 0 0 1-.4-.44l-.02-.1-.01-.13V20l.01-.13.03-.12a.7.7 0 0 1 .41-.43l.1-.02.12-.01h.66v-1.43l.01-.22.02-.22A2.73 2.73 0 0 1 14 15zm0 5.71c-.37 0-.67.32-.67.72l.03.18.02.09.06.12.08.1.06.06-.25 1.6h1.34l-.25-1.6.06-.06.08-.1.06-.12a.75.75 0 0 0 .05-.27c0-.4-.3-.72-.67-.72zm0-4.28c-.59 0-1.08.4-1.26.97l-.04.13-.02.16-.01.17v1.43h2.66V17.69l-.03-.16a1.36 1.36 0 0 0-1.3-1.1zm4-11.01V11h5.59L18 5.42z"
      />
    </Svg>
  )
}

export default DocumentsLockedL
