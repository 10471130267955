import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_XL } from './constants'

const EmployersXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M17.488 8.488A1.666 1.666 0 0 1 18.667 8h10.666A1.666 1.666 0 0 1 31 9.667V13H17V9.667a1.666 1.666 0 0 1 .488-1.179ZM15 13V9.667A3.666 3.666 0 0 1 18.667 6h10.666A3.667 3.667 0 0 1 33 9.667V13h3.143v2H11.857C7 15 7 19.6 7 19.6c-2 0-2-.001-2-.002v-.045a4.16 4.16 0 0 1 .015-.301c.014-.182.04-.428.091-.717.1-.57.305-1.358.732-2.169a6.106 6.106 0 0 1 2.143-2.338C9.023 13.37 10.314 13 11.857 13H15Zm5 13.5H7v-6.9H5v13.8c0 1.523.41 2.796 1.123 3.81.702.997 1.614 1.627 2.45 2.023.83.393 1.634.58 2.213.67a8.309 8.309 0 0 0 1.027.097H11.855c.001 0 .002 0 .002-2v2h24.286c1.543 0 2.834-.37 3.876-1.028a6.106 6.106 0 0 0 2.142-2.338c.428-.81.633-1.599.733-2.169A7.333 7.333 0 0 0 43 33.447v-.045s0-.002-2-.002h2V19.6c0-1.523-.41-2.796-1.123-3.81-.702-.997-1.614-1.627-2.45-2.023a8.213 8.213 0 0 0-2.213-.67A8.309 8.309 0 0 0 36.187 13H36.145c-.001 0-.002 0-.002 2 0 0 4.857 0 4.857 4.6v6.9H28V26a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v.5Zm0 2H7v4.9C7 38 11.857 38 11.857 38h24.286C41 38 41 33.4 41 33.4v-4.9H28V30a3 3 0 0 1-3 3h-2a3 3 0 0 1-3-3v-1.5Zm2 0V30a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2.5Z"
      />
    </Svg>
  )
}

export default EmployersXL
