import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const NotificationsL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        transform="translate(-16 -279) translate(16 279)"
        d="M19.5 24.5a3.5 3.5 0 0 1-7 .2v-.2h7zM16 4c.76 0 1.37.6 1.42 1.36v1.05a7.17 7.17 0 0 1 5.72 6.9v.19c0 2.77.57 4.4 1.2 5.46l.13.2.12.18.15.2.15.2.12.14.18.2.43.47c.26.3.38.64.38.99 0 .72-.53 1.4-1.33 1.46H7.43c-.85 0-1.42-.71-1.43-1.46 0-.35.12-.7.38-1l.43-.45.18-.2.12-.15.12-.15.12-.17.12-.17.12-.19c.65-1.03 1.23-2.6 1.27-5.3v-.26a7.17 7.17 0 0 1 5.53-7.05l.18-.04v-.95c0-.8.64-1.46 1.43-1.46zm-.03 4.15l-1.11.25a5.17 5.17 0 0 0-4 4.87v.51c-.04 2.6-.53 4.54-1.44 6.11l-.15.26-.16.24-.16.23-.16.2-.13.18h14.68l-.21-.28-.2-.26-.16-.24-.14-.23c-.92-1.53-1.43-3.47-1.49-6.03v-.6a5.17 5.17 0 0 0-3.9-4.94L17 8.37l-1.04-.22z"
      />
    </Svg>
  )
}

export default NotificationsL
