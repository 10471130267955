import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const EventL: React.FC<IconPropsT> = ({ fill = '#505673', sx }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill} sx={sx}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.624 2.703c-.356.07-.75.314-1.002.62-.104.125-.513.923-2.657 5.18l-1.041 2.068-3.338.329c-1.837.18-3.429.346-3.539.367-.647.123-1.21.68-1.345 1.33-.107.511.036 1.087.365 1.467.063.073 1.359 1.368 2.88 2.878 1.916 1.902 2.76 2.757 2.752 2.787-.09.302-2.042 7.443-2.064 7.546-.071.338-.008.778.159 1.107a1.747 1.747 0 0 0 2.262.798c.066-.03 1.648-.811 3.515-1.737a432.161 432.161 0 0 1 3.413-1.683c.01 0 1.583.775 3.493 1.722 1.911.947 3.546 1.747 3.634 1.777.358.124.896.088 1.26-.084.48-.227.855-.698.963-1.21a2.2 2.2 0 0 0 .003-.721 1846.72 1846.72 0 0 0-2.064-7.528c-.006-.017 1.242-1.27 2.773-2.788 1.532-1.516 2.839-2.829 2.905-2.917.167-.221.24-.375.316-.669.067-.258.09-.625.039-.625-.015 0-.027-.027-.027-.06 0-.123-.11-.407-.237-.62a2.048 2.048 0 0 0-.5-.508 1.483 1.483 0 0 0-.519-.236c-.136-.034-6.731-.71-6.928-.71-.032 0-.354-.616-1.169-2.233-2.034-4.042-2.474-4.9-2.58-5.028a1.83 1.83 0 0 0-1.025-.62 1.691 1.691 0 0 0-.697.001ZM14.061 8.07c-1.765 3.512-1.882 3.737-2.02 3.877a.696.696 0 0 1-.28.193c-.09.03-1.327.163-3.767.405l-3.645.36c-.078 0 .43.52 2.994 3.063 2.517 2.497 3 2.988 3.06 3.11.144.295.196.064-1.023 4.492a338.248 338.248 0 0 0-1.096 4.035c0 .028.018.05.044.05.024 0 1.646-.794 3.604-1.765 1.957-.97 3.625-1.794 3.705-1.83a.733.733 0 0 1 .35-.068l.202-.001.797.39a1502 1502 0 0 1 3.727 1.844c2.302 1.142 2.938 1.447 2.964 1.421.025-.025-.203-.884-1.074-4.046-1.036-3.76-1.105-4.026-1.098-4.2.015-.364-.163-.169 3.137-3.439 1.642-1.627 2.985-2.975 2.985-2.995a.057.057 0 0 0-.033-.049c-.019-.007-1.658-.175-3.644-.372-1.985-.197-3.654-.372-3.71-.387-.118-.034-.293-.165-.394-.293-.04-.052-.915-1.765-1.944-3.808-1.445-2.87-1.881-3.714-1.918-3.714-.037 0-.473.845-1.922 3.727Zm-11.39 4.888c0 .125.005.176.01.113a1.526 1.526 0 0 0 0-.226c-.005-.063-.01-.011-.01.113Z"
        fill={fill}
      />
    </Svg>
  )
}

export default EventL
