import React, { useEffect, useState } from 'react'
import useFilterContext from 'components/Filter/context'
import { SimpleLoading } from 'components/LoadingIcon'
import LoadMoreButton from 'components/LoadMoreButton'
import { TaskDataT, TaskT } from '../types'
import useTasksQueryPaginated from '../../../hooks/useTasksQueryPaginated'
import TaskCard from '../TaskCard'
import ConfirmDeleteDialog from './ConfirmDeleteDialog'
import SendReminderSuccessDialog from './SendReminderSuccessDialog'
import NoTaskBox from './NoTaskBox'

const TASKLIMIT = 20

interface ITaskList {
  office: boolean
  refetchTaskCounts: () => void
}

const TaskList: React.FC<ITaskList> = ({ office, refetchTaskCounts }) => {
  const { getFilters } = useFilterContext()
  const filters = getFilters()
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [sendReminderSuccessOpen, setSendReminderSuccessOpen] = useState(false)
  const [taskToDelete, setTaskToDelete] = useState<TaskT<TaskDataT>>()
  const {
    updateFilters,
    updateOffice,
    hasMoreElements,
    list: tasks,
    loading: tasksLoading,
    loadMore,
    reload,
  } = useTasksQueryPaginated({ initialFilters: filters, initialOffice: office, limit: TASKLIMIT })

  useEffect(() => {
    updateFilters(filters)
  }, [filters, updateFilters])

  useEffect(() => {
    updateOffice(office)
  }, [office, updateOffice])

  const onDelete = (task: TaskT<TaskDataT>) => {
    setTaskToDelete(task)
    setConfirmDeleteOpen(true)
  }

  const onDeleteSucess = () => {
    reload()
    refetchTaskCounts()
  }

  const onDeleteClose = () => {
    setConfirmDeleteOpen(false)
  }

  return (
    <>
      {confirmDeleteOpen && taskToDelete && (
        <ConfirmDeleteDialog onClose={onDeleteClose} task={taskToDelete} onDeleteSuccess={onDeleteSucess} />
      )}
      {sendReminderSuccessOpen && (
        <SendReminderSuccessDialog onClose={() => setSendReminderSuccessOpen(false)} isOffice={office} />
      )}
      {!tasksLoading && tasks.length === 0 && <NoTaskBox />}
      {tasks.map((task) => (
        <TaskCard
          key={task.favurUuid}
          task={task}
          office={office}
          onDelete={onDelete}
          onSentReminderSuccess={() => setSendReminderSuccessOpen(true)}
        />
      ))}
      {tasksLoading && <SimpleLoading />}
      {!tasksLoading && Boolean(hasMoreElements) && <LoadMoreButton onClick={loadMore} testId="tasks-load-more" />}
    </>
  )
}

export default TaskList
