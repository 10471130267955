import React, { useContext, useMemo } from 'react'
import { Box, Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TaskDataT, TaskT } from 'pages/Tasks/types'
import useRolesViews from 'hooks/useRolesViews'
import { AuthPersonIdAndPermissions } from 'types'
import { deleteTaskQuery } from 'pages/Tasks/queries'
import FlashMessagesContext from 'services/FlashMessages/context'
import { MutationResult, useMutation } from '@apollo/client'
import { taskTypes } from 'shared/constants'
import { classes } from './styles'

const getPersonId = (persons: AuthPersonIdAndPermissions[] | undefined, task: TaskT<TaskDataT>) => {
  if (!persons) return undefined
  if (task.taskType === taskTypes.absenceRequest) {
    return persons.find((person) => task.person && `${person.id}` === `${task.person.id}`)?.id
  }
  if (task.taskType === taskTypes.shareUserData) {
    return persons.find((person) => person.permissions.workflowShareUserData)?.id
  }
  return persons.find((person) => person.permissions.workflowMonthlySheets)?.id
}

interface IConfirmDeleteDialogProps {
  onClose: () => void
  task: TaskT<TaskDataT>
  onDeleteSuccess: () => void
}

const ConfirmDeleteDialog: React.FC<IConfirmDeleteDialogProps> = ({ onClose, task, onDeleteSuccess }) => {
  const { t } = useFavurTranslation()
  const { persons } = useRolesViews()
  const personId = useMemo(() => getPersonId(persons, task), [persons, task])
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)

  const [deleteTaskMutation] = useMutation<MutationResult>(deleteTaskQuery)

  const onSubmit = () => {
    removeAll()
    if (!personId) return setFlashMessage('common.error.label.errorTitle')
    return deleteTaskMutation({ variables: { favurUuid: task.favurUuid, personId } })
      .then(() => {
        onDeleteSuccess()
        onClose()
      })
      .catch((_e) => setFlashMessage('common.error.be.default'))
  }

  const dialogActions = (
    <Box>
      <Button variant="text" onClick={() => onClose()} color="secondary">
        {t('common.cancel')}
      </Button>
      <Button variant="text" onClick={() => onSubmit()} color="secondary" data-testid="delete-task">
        {t('tasks.delete.accept')}
      </Button>
    </Box>
  )
  return (
    <Dialog
      open
      title={task && t(`tasks.delete.${task.taskType}.title`)}
      actions={dialogActions}
      data-testid="confirmation-dialog"
    >
      <Typography variant="body2" sx={classes.body1}>
        {task && t(`tasks.delete.${task.taskType}.body`)}
      </Typography>
    </Dialog>
  )
}

export default ConfirmDeleteDialog
