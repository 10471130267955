import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const CalendarL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13 4c.6 0 1 .4 1 1v2h4V5c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v2h3a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h3V5c0-.6.4-1 1-1h2zm12 10H7v12h18V14zm-7.8 7c.4 0 .8.4.8.8v.4c0 .4-.4.8-.8.8H9.8a.8.8 0 0 1-.8-.8v-.4c0-.4.4-.8.8-.8h7.4zm5-4c.4 0 .8.4.8.8v.4c0 .4-.4.8-.8.8H9.8a.8.8 0 0 1-.8-.8v-.4c0-.4.4-.8.8-.8h12.4zM25 9H7v3h18V9z"
      />
    </Svg>
  )
}

export default CalendarL
