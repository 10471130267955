import { useMutation } from '@apollo/client'
import { useContext, useEffect } from 'react'
import FlashMessagesContext from 'services/FlashMessages/context'
import { MutationResult } from 'types'
import { dismissTaskNotificationsMutation } from '../mutations'

interface IUseDismissTaskNotification {
  isManager: boolean
  taskUuid: string | null
}

const useDismissTaskNotifications = ({ isManager, taskUuid }: IUseDismissTaskNotification) => {
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [dismissNotifications, { loading }] = useMutation<{ dismissByTask: MutationResult }>(
    dismissTaskNotificationsMutation,
  )

  useEffect(() => {
    removeAll()
    if (!taskUuid) return
    dismissNotifications({ variables: { taskUuid, isManager } }).then((res) => {
      if (!res.data?.dismissByTask.success) {
        setFlashMessage('common.error.be.default')
      }
    })
  }, [dismissNotifications, isManager, removeAll, setFlashMessage, taskUuid])

  return { loading }
}

export default useDismissTaskNotifications
