import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { isEqual } from 'lodash/fp'
import { useFieldArray } from 'react-hook-form'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Grid from 'components/Grid'
import { SimpleLoading } from 'components/LoadingIcon'
import DatePicker from 'components/DatePickers/UncontrolledDatePicker'
import { newDateWithoutTime } from 'utils/date'
import UncontrolledSelect from '../components/UncontrolledSelect'
import PersonForm from '../components/PersonBlockForm'
import InfoAdornment from '../components/InfoAdornment'
import InfoDialog from '../components/InfoDialog'
import FormsButtons from '../components/Buttons'
import { hasPartner, removeOrAppendKids } from '../helpers'
import { classes } from '../styles'
import { civilStatusFieldsTrigger, civilStatusValues, kidInputKeys, partnerInputKeys } from '../constants'
import SendShareUserDataCard from '../components/SendShareUserDataCard'
import { useFormsContext } from '../context'
import type { FamilyFormValuesKeysT, FamilyFormValuesT } from './types'
import type { DataFormT, FormHandlersT, IDefaultFormProps } from '../types'

const FamilyForm: React.FC<IDefaultFormProps> = ({
  changeTabTrigger,
  onCancel,
  setChangeTabTrigger,
  setIsFormDirty,
  accessPoint,
  tabChangeCallback,
}) => {
  const { t } = useFavurTranslation()
  const [openCivilStatusSinceDialog, setOpenCivilStatusSinceDialog] = useState(false)
  const [openChildrenDialog, setOpenChildrenDialog] = useState(false)
  const [openPartnerDialog, setOpenPartnerDialog] = useState(false)
  const { getFamilyDataForm } = useFormsContext()
  const { favurForm, formHandlers, defaultValues, formReady } = getFamilyDataForm() as DataFormT<
    FamilyFormValuesT,
    FamilyFormValuesT
  >
  // eslint-disable-next-line fp/no-mutating-methods
  const [kidsNumber, civilStatus] = favurForm.watch(['kidsNumber', 'civilStatus'])
  const { fields, remove, append } = useFieldArray({
    control: favurForm.control,
    name: 'children',
  })

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(favurForm.formState.isDirty)
    }
  }, [favurForm.formState.isDirty, setIsFormDirty])

  useEffect(() => {
    if (changeTabTrigger && setChangeTabTrigger) {
      favurForm.handleSubmit()
      setChangeTabTrigger(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTabTrigger])

  useEffect(() => {
    if (favurForm.formState.isSubmitted && isEqual(favurForm.formState.errors, {})) {
      tabChangeCallback && tabChangeCallback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favurForm.formState.isSubmitted])

  useEffect(() => {
    favurForm.reset({}, { keepValues: true, keepDirty: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!formReady) {
    return <SimpleLoading />
  }

  return (
    <>
      <Grid container data-testid="family-form">
        <Grid sx={classes.gridForFormField} col={4}>
          <UncontrolledSelect
            translate
            sortOptions
            required
            name="civilStatus"
            formHandlers={formHandlers('civilStatus')}
            defaultValue={defaultValues?.civilStatus}
            onChange={(e) => {
              if (!hasPartner(e.target.value)) {
                civilStatusFieldsTrigger.forEach((key) => favurForm.setValue(key as FamilyFormValuesKeysT, ''))
              }
            }}
            options={civilStatusValues}
          />
        </Grid>
        {civilStatus !== 'single' && (
          <Grid sx={classes.gridForFormField} col={4}>
            <DatePicker
              required={false}
              formField="civilStatusSince"
              dataTestId="user-personal-data_civil-status-since"
              favurForm={favurForm}
              formHandlers={formHandlers('civilStatusSince')}
              minDate={newDateWithoutTime('1900-01-01')}
              useDefaultValue={false}
            />
            <InfoAdornment type="title" open={openCivilStatusSinceDialog} setOpen={setOpenCivilStatusSinceDialog} />
            <InfoDialog
              open={openCivilStatusSinceDialog}
              action={() => setOpenCivilStatusSinceDialog(false)}
              text={t('personalData.civilStatusSince.dialog')}
            />
          </Grid>
        )}
        <Grid sx={classes.gridForFormField} col={4}>
          <UncontrolledSelect
            required
            name="kidsNumber"
            formHandlers={formHandlers('kidsNumber')}
            defaultValue={defaultValues?.kidsNumber}
            onChange={(e) => {
              const newKidsNumber = parseInt(e.target.value)
              // @ts-ignore
              removeOrAppendKids({ oldKidsNumber: kidsNumber ?? 0, newKidsNumber, remove, append })
              favurForm.setValue('kidsNumber', newKidsNumber)
            }}
          />
          <InfoAdornment type="title" open={openChildrenDialog} setOpen={setOpenChildrenDialog} />
          <InfoDialog
            open={openChildrenDialog}
            action={() => setOpenChildrenDialog(false)}
            text={t('personalData.kidsNumber.dialog')}
          />
        </Grid>
        {hasPartner(civilStatus ?? '') && (
          <>
            <Grid sx={classes.gridForSubtitle} col={4}>
              <Typography variant="subtitle1">{t('personalData.partner.title')}</Typography>
              <InfoAdornment type="title" open={openPartnerDialog} setOpen={setOpenPartnerDialog} />
              <InfoDialog
                open={openPartnerDialog}
                action={() => setOpenPartnerDialog(false)}
                text={t('personalData.partner.dialog')}
              />
            </Grid>
            <PersonForm
              favurForm={favurForm}
              formHandlers={formHandlers as FormHandlersT}
              namePrefix="partner"
              inputKeys={partnerInputKeys}
            />
          </>
        )}
        {fields.map((item, index) => (
          // @ts-ignore
          <Grid key={item.id} container item col={4} data-testid={`family-data_child-${index}`}>
            <Grid sx={classes.gridForSubtitle} col={4}>
              <Typography variant="subtitle1">{`${index + 1}. ${t('personalData.children.title')}`}</Typography>
            </Grid>
            <PersonForm
              favurForm={favurForm}
              formHandlers={formHandlers as FormHandlersT}
              namePrefix="children"
              inputKeys={kidInputKeys}
              iterator={{ item, index }}
            />
          </Grid>
        ))}
      </Grid>
      {accessPoint === 'userSettings' && <SendShareUserDataCard />}
      {accessPoint !== 'shareUserDataWorkflow' && (
        <FormsButtons onCancel={onCancel} onSubmit={favurForm.handleSubmit} accessPoint={accessPoint} />
      )}
    </>
  )
}

export default FamilyForm
