import React from 'react'
import Page from 'components/Page'
import useFilterContext from './context'
import FilterPage from './Page'
import PersonsList from './Page/Persons/PersonsList'
import { PersonsT, QuickFilterT } from './types'
import Filter from '.'

interface IPageWithFilterProps {
  children?: React.ReactNode
  filterPageTitle: string
  header?: React.ReactElement | string | null
  quickFilter?: QuickFilterT
}

const PageWithFilter: React.FC<IPageWithFilterProps> = ({ children, filterPageTitle, header, quickFilter }) => {
  const { isFilterPageOpen, getPersonsListSelected, getConfiguration } = useFilterContext()

  const personsListSelected = getPersonsListSelected()
  if (personsListSelected) {
    const personListOption = getConfiguration().find((option) => option.name === personsListSelected) as PersonsT
    return <PersonsList option={personListOption} />
  }

  if (isFilterPageOpen()) {
    return <FilterPage headerTitle={filterPageTitle} />
  }

  return (
    <Page header={header} topContent={<Filter quickFilter={quickFilter} />}>
      {children}
    </Page>
  )
}

export default PageWithFilter
