import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Card from 'components/Basics/Card'
import ContactForm from 'components/Forms/Contact'
import { formClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import type { IEmploymentFormProps } from '../types'

const ContactData: React.FC<IEmploymentFormProps> = ({
  changeTabTrigger,
  setChangeTabTrigger,
  setIsFormDirty,
  tabChangeCallback,
}) => {
  const history = useHistory()

  return (
    <Box component="form" sx={formClasses.container}>
      <Card>
        <ContactForm
          changeTabTrigger={changeTabTrigger}
          onCancel={() => history.push(routes.userSettings)}
          setChangeTabTrigger={setChangeTabTrigger}
          setIsFormDirty={setIsFormDirty}
          accessPoint="userSettings"
          tabChangeCallback={tabChangeCallback}
        />
      </Card>
    </Box>
  )
}

export default ContactData
