import React from 'react'
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  PaperProps as MuiPaperProps,
  Typography,
  SxProps,
} from '@mui/material'
import { dialogClasses } from './styles'
interface IDialogProps extends MuiDialogProps {
  title?: string
  PaperProps?: Partial<MuiPaperProps>
  actions?: React.ReactElement | string
  actionSx?: SxProps
  testId?: string
}

const Dialog: React.FC<IDialogProps> = ({ title, actions, PaperProps, children, actionSx, testId, ...props }) => {
  return (
    <MuiDialog
      PaperProps={{
        ...PaperProps,
        sx: [dialogClasses.dialog, ...(Array.isArray(props.sx) ? props.sx : [props.sx])],
      }}
      disableEscapeKeyDown
      onClose={(e, reason) => {
        if (props.onClose && reason !== 'backdropClick') {
          props.onClose(e, reason)
        }
      }}
      data-testid={testId ?? 'dialog'}
      {...props}
    >
      {title && (
        <DialogTitle sx={dialogClasses.title}>
          <Typography variant="subtitle1" role="heading" data-testid="dialog-title-text" component="span">
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent sx={dialogClasses.content}>{children}</DialogContent>
      {actions && (
        <DialogActions sx={[dialogClasses.actions, ...(Array.isArray(actionSx) ? actionSx : [actionSx])]}>
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  )
}

export default Dialog
