import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const HomeNewL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M25.5 16.5c0-.2761-.2239-.5-.5-.5h-1c-.2761 0-.5.2239-.5.5V26h-14v-9.5c0-.2761-.22386-.5-.5-.5H8c-.27614 0-.5.2239-.5.5V27c0 .5523.44772 1 1 1h16c.5523 0 1-.4477 1-1V16.5Z"
      />
      <path
        fillRule="evenodd"
        d="M16.5 4.2085c.1334-.01844.2739.01679.3861.10912L29.1535 14.4076c.2133.1754.244.4905.0686.7038l-.6353.7723c-.1754.2133-.4905.244-.7037.0685L16.5 6.58961 5.11689 15.9522c-.21327.1755-.52836.1448-.70377-.0685l-.63524-.7723c-.17541-.2133-.14473-.5284.06854-.7038L16.1138 4.31762c.1123-.09233.2527-.12756.3862-.10912Z"
      />
    </Svg>
  )
}

export default HomeNewL
