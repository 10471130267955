import { FILE_NAME_DELIMITER } from './constants'
import type { DocumentContentBlockT } from 'types'

export const getFilenameWithoutTimestamp = ({ filename }: Pick<DocumentContentBlockT, 'filename'>) => {
  const filenamePieces = filename.split('.')

  if (filenamePieces.length <= 1) {
    return filename
  }

  const filenameWithoutTimestamp = filenamePieces[0].split(FILE_NAME_DELIMITER)[0]
  const extension = filenamePieces[filenamePieces.length - 1]

  return `${filenameWithoutTimestamp}.${extension}`
}
