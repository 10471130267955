import Page from 'components/Page'
import React from 'react'
import AbsencePlannerPageHeader from './components/PageHeader'
import { SelectedStatesProvider } from './contexts/selectedStatesContext'
import AbsencePlannerGrid from './components/Grid'
import useAbsencePlannerStateContext from './contexts/AbsencePlannerStateContext'
import useTaskBarStateContext from './contexts/TaskBarStateContext'
import { taskBarStates } from './components/TaskBar/types'

const AbsencePlannerPage: React.FC = () => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { taskBarState } = useTaskBarStateContext()
  const hideHeader =
    isSmallScreen &&
    (taskBarState === taskBarStates.managerTasks ||
      taskBarState === taskBarStates.userTasks ||
      taskBarState === taskBarStates.newAbsenceRequest ||
      taskBarState === taskBarStates.events)
  return (
    <Page
      header={hideHeader ? null : <AbsencePlannerPageHeader />}
      fullContentWidth
      noPadding
      removeLastMargin
      fullContentHeight
      hideHamburger={hideHeader}
      hideWebToolbar={hideHeader}
      hideNativeNavigation={hideHeader}
    >
      <SelectedStatesProvider>
        <AbsencePlannerGrid />
      </SelectedStatesProvider>
    </Page>
  )
}

export default AbsencePlannerPage
