import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ArrowDownM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7645 7.82323C21.155 8.21375 21.155 8.84692 20.7645 9.23744L13.118 16.8839C12.7275 17.2744 12.0944 17.2744 11.7038 16.8839L4.05739 9.23744C3.66686 8.84691 3.66687 8.21375 4.05739 7.82323L4.76448 7.11614C5.15501 6.7256 5.78819 6.72561 6.17871 7.11615L12.4109 13.3487L18.6432 7.11615C19.0337 6.72561 19.6669 6.72561 20.0574 7.11614L20.7645 7.82323Z"
      />
    </Svg>
  )
}

export default ArrowDownM
