import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import BackwardLink from 'components/BackwardLink'
import Page from 'components/Page'
import useStateBackLink from 'hooks/useStateBackLink'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { headerClasses } from 'pages/UserSettings/styles'
import { SimpleLoading } from 'components/LoadingIcon'
import ActionButtons from 'components/Buttons/ActionButtons'
import usePackage from 'hooks/usePackage'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { SettingsPushNotificationsOptionsT } from 'types'
import { classes } from './styles'
import NotificationsSettingsSection from './components/Section'
import DisableDialog from './components/DisableDialog'
import {
  adminSettings,
  cmsSettings,
  communicationAbsenceBetaSettings,
  communicationSettings,
  documentsSettings,
  workingHoursSettings,
} from './constants'
import useNotificationSettings from './useNotificationSettings'

type SectionConfigT = {
  title: string
  settings: SettingsPushNotificationsOptionsT[]
  condition: boolean
  changeInApp?: boolean
}

const NotificationSettingsPage: React.FC = () => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { hasOfficeView } = useRolesViews()
  const { hasAbsenceBetaWorkflow, hasClassicLightAccess } = usePackage()
  const { cmsActivated } = useSessionContext()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const {
    pushSwitchStates,
    inAppSwitchStates,
    loading,
    dialogOpen,
    handlePushChange,
    handleInAppChange,
    handleClose,
    disableAnyway,
    onSubmit,
  } = useNotificationSettings()

  const fullCommunicationSettings = useMemo(
    () => [...communicationSettings, ...(hasAbsenceBetaWorkflow ? communicationAbsenceBetaSettings : [])],
    [hasAbsenceBetaWorkflow],
  )

  const showAdminSettings = useMemo(() => hasOfficeView && hasAbsenceBetaWorkflow && hasClassicLightAccess, [
    hasAbsenceBetaWorkflow,
    hasClassicLightAccess,
    hasOfficeView,
  ])

  const sections = useMemo(
    (): SectionConfigT[] => [
      {
        title: t('page.notificationsSettings.section.workingHours'),
        settings: workingHoursSettings,
        condition: hasClassicLightAccess,
      },
      {
        title: t('page.notificationsSettings.section.documents'),
        settings: documentsSettings,
        condition: true,
      },
      {
        title: t('page.notificationsSettings.section.communication'),
        settings: fullCommunicationSettings,
        condition: hasClassicLightAccess,
      },
      {
        title: t('page.notificationsSettings.section.admin'),
        settings: adminSettings,
        condition: showAdminSettings,
        changeInApp: true,
      },
      {
        title: t('page.notificationsSettings.section.cms'),
        settings: cmsSettings,
        condition: cmsActivated,
      },
    ],
    [fullCommunicationSettings, hasClassicLightAccess, showAdminSettings, t, cmsActivated],
  )

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushBackLinkOrDefault(routes.userSettings)
          }}
          title={t('page.notifications.header.title')}
        />
      }
      sxHeader={headerClasses.subHeader}
      hideNativeNavigation
    >
      {loading ? (
        <SimpleLoading />
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <Box sx={classes.sectionsContainer}>
              {sections.map(({ condition, settings, title, changeInApp }) =>
                condition ? (
                  <NotificationsSettingsSection
                    title={title}
                    settings={settings}
                    handlePushChange={handlePushChange}
                    pushSwitchStates={pushSwitchStates}
                    handleInAppChange={handleInAppChange}
                    inAppSwitchStates={inAppSwitchStates}
                    key={title}
                    changeInApp={changeInApp}
                  />
                ) : null,
              )}
              <ActionButtons
                cancelAction={() => history.push(routes.userSettings)}
                cancelLabel={t('page.userSettings.personalData.actions.cancel')}
                isFloating
                noNavigation
                isValidateSubmit
                validateLabel={t('page.userSettings.personalData.actions.save')}
              />
            </Box>
          </form>
          <DisableDialog handleClose={handleClose} dialogOpen={dialogOpen} disableAnyway={disableAnyway} />
        </>
      )}
    </Page>
  )
}

export default NotificationSettingsPage
