import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const InfoInvertedS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8C3 5.23967 5.23891 3 8 3C10.7611 3 13 5.23967 13 8C13 10.7621 10.7613 13 8 13C5.2387 13 3 10.7621 3 8ZM8 2C4.68652 2 2 4.68749 2 8C2 11.3144 4.68652 14 8 14C11.3135 14 14 11.3144 14 8C14 4.68749 11.3135 2 8 2ZM7.99996 5C8.50208 5 8.90912 5.40704 8.90912 5.90916C8.90912 6.41127 8.50208 6.81831 7.99996 6.81831C7.49785 6.81831 7.09081 6.41127 7.09081 5.90916C7.09081 5.40704 7.49785 5 7.99996 5ZM9.21219 10.4982C9.21219 10.6416 9.09589 10.7579 8.95244 10.7579H7.04754C6.90409 10.7579 6.78778 10.6416 6.78778 10.4982V9.97865C6.78778 9.8352 6.90409 9.71889 7.04754 9.71889H7.3073V8.33351H7.04754C6.90409 8.33351 6.78778 8.21721 6.78778 8.07375V7.55424C6.78778 7.41079 6.90409 7.29448 7.04754 7.29448H8.43292C8.57637 7.29448 8.69268 7.41079 8.69268 7.55424V9.71889H8.95244C9.09589 9.71889 9.21219 9.8352 9.21219 9.97865V10.4982Z"
        fill={fill}
      />
    </Svg>
  )
}

export default InfoInvertedS
