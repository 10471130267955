import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const CalendarShiftM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M16.5 13h-9a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm1.5 8H6a2 2 0 01-2-2V7a2 2 0 012-2h2V3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V5h4V3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V5h2a2 2 0 012 2v12a2 2 0 01-2 2zm0-3v-8a1 1 0 00-1-1H7a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1z"
      />
    </Svg>
  )
}

export default CalendarShiftM
