import { Box } from '@mui/material'
import React from 'react'
import { ImageContentBlockT } from 'types'
import { imageBlockStyles } from '../styles'

interface IImageBlockProps {
  data: ImageContentBlockT
}

const ImageBlock: React.FC<IImageBlockProps> = ({ data }) => (
  <>{data.src && <Box component="img" sx={imageBlockStyles.image} src={data.src} alt={data.altText}></Box>}</>
)

export default ImageBlock
