import React, { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import { FF_SECURE_CONN_WORKFLOW } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { RemoveS, SuccessS, TeamM } from 'icons'
import theme from 'utils/theme'
import { getTeamNameByLanguage } from 'types/utils'
import { TeamTabs } from 'pages/Teams/TeamPage/constants'
import { MutationResult, PersonT, TeamT, WorkflowsT } from 'types'
import routes from 'services/RoutesProvider/routes'
import FlashMessagesContext from 'services/FlashMessages/context'
import DialogWindow from 'components/Dialog/DialogWindow'
import { getUserName } from 'utils/person'
import { updateMembers } from 'pages/Teams/queries'
import { classes } from './styles'

interface ITeamDataProps {
  onRemoveFromTeam: () => void
  person: PersonT
  teamAndPermissions: {
    team: TeamT
    activePermissionNames: string[]
  }
}

const TeamData: React.FC<ITeamDataProps> = ({ onRemoveFromTeam, person, teamAndPermissions }) => {
  const { t, language } = useFavurTranslation()
  const history = useHistory()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const [showDialog, setShowDialog] = useState(false)
  const [mutate] = useMutation<{ updateTeamMembers: MutationResult }>(updateMembers)
  const secureConnWorkflowFF = useFeatureFlag(FF_SECURE_CONN_WORKFLOW) === true

  const onSubmit = useCallback(async () => {
    const teamId = teamAndPermissions.team.id

    const result = await mutate({
      variables: {
        changes: {
          add: [],
          delete: [person.id],
        },
        teamId,
      },
    })

    if (result.data?.updateTeamMembers.success) {
      onRemoveFromTeam()
    } else {
      setFlashMessage(t('teams.editMembers.error'))
    }
  }, [mutate, onRemoveFromTeam, person.id, setFlashMessage, t, teamAndPermissions.team.id])

  return (
    <>
      {showDialog && (
        <DialogWindow
          bodyText={t('component.employeeDetail.removeTeamMemberBody', { userName: getUserName(person) })}
          onCancel={() => {
            setShowDialog(false)
          }}
          onConfirm={onSubmit}
          open={showDialog}
          titleText={t('component.employeeDetail.removeAdminTeamButton')}
        />
      )}
      <Card marginBottom={1.125}>
        <Box sx={classes.teamDataContainer}>
          <Box sx={classes.teamNameTitle}>
            <TeamM fill={theme.palette.primary.main} />
            <Typography variant="subtitle1" sx={classes.teamNameTitleText}>
              {getTeamNameByLanguage(teamAndPermissions.team, language)}
            </Typography>
          </Box>
          <Box sx={classes.permissionTitle}>
            <Typography variant="subtitle1">{t('editPermissions.workloadPermissionsTitle')}</Typography>
            <Button
              type="submit"
              size="small"
              variant="text"
              color="secondary"
              onClick={() => history.push(`${routes.teams}/${teamAndPermissions.team.id}/${TeamTabs.rights}`)}
            >
              {t(`teams.permissions.buttonEdit`)}
            </Button>
          </Box>
          {teamAndPermissions.activePermissionNames.length > 0 ? (
            <Box>
              {teamAndPermissions.activePermissionNames.map((permissionName) =>
                // TBD" Remove FF_SECURE_CONN_WORKFLOW and the first condition. Box must always be showed
                !secureConnWorkflowFF ? (
                  permissionName !== WorkflowsT.secureConnectionManager && (
                    <Box key={permissionName}>
                      <SuccessS fill={theme.palette.primary.main} />
                      <Typography sx={classes.caption} variant="caption">
                        {t(`teams.permissions.${permissionName}.title`)}
                      </Typography>
                    </Box>
                  )
                ) : (
                  <Box key={permissionName}>
                    <SuccessS fill={theme.palette.primary.main} />
                    <Typography sx={classes.caption} variant="caption">
                      {t(`teams.permissions.${permissionName}.title`)}
                    </Typography>
                  </Box>
                ),
              )}
            </Box>
          ) : (
            <Typography variant="subtitle1" sx={classes.noAdminText}>
              {t('component.employeeDetail.noAdmin')}
            </Typography>
          )}

          <DivisionLine />
          <Box sx={classes.removeAdminButtonContainer}>
            <Button
              type="submit"
              size="small"
              variant="text"
              color="primary"
              startIcon={<RemoveS />}
              onClick={() => setShowDialog(true)}
              sx={classes.removeButton}
            >
              {t('component.employeeDetail.removeAdminTeamButton')}
            </Button>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default TeamData
