import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const EventS: React.FC<IconPropsT> = ({ fill = '#505673', sx }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill} sx={sx}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.78.022a1.12 1.12 0 0 0-.601.372c-.062.075-.308.554-1.596 3.108l-.625 1.24-2.004.197c-1.103.109-2.059.208-2.125.22a1.058 1.058 0 0 0-.808.798c-.064.307.022.652.219.88.038.044.816.821 1.73 1.727 1.15 1.14 1.657 1.654 1.652 1.672-.053.181-1.226 4.464-1.24 4.526a1.08 1.08 0 0 0 .096.664c.25.493.85.704 1.359.479.04-.018.99-.486 2.11-1.042 1.121-.555 2.044-1.01 2.05-1.01.006 0 .95.465 2.098 1.034 1.147.568 2.13 1.047 2.182 1.066.215.074.538.052.756-.051.289-.136.514-.419.579-.726.024-.114.025-.328.001-.433-.015-.068-1.223-4.47-1.24-4.515-.002-.01.747-.762 1.667-1.672.92-.91 1.704-1.698 1.744-1.75a.98.98 0 0 0 .19-.402c.04-.154.054-.375.023-.375-.009 0-.016-.016-.016-.036 0-.074-.066-.244-.143-.372a1.228 1.228 0 0 0-.3-.304.89.89 0 0 0-.311-.142c-.082-.02-4.042-.425-4.16-.426-.02 0-.214-.37-.703-1.34C9.143.985 8.88.471 8.814.393A1.1 1.1 0 0 0 8.2.021a1.017 1.017 0 0 0-.419.001Zm-.938 3.22c-1.06 2.106-1.13 2.24-1.213 2.325a.419.419 0 0 1-.168.116c-.054.017-.797.097-2.262.242-1.2.12-2.185.216-2.19.216-.046 0 .26.312 1.799 1.838C4.319 9.477 4.609 9.77 4.645 9.845c.087.177.118.038-.613 2.694a202.961 202.961 0 0 0-.66 2.42c.001.018.012.03.027.03s.989-.476 2.164-1.058a489.338 489.338 0 0 1 2.226-1.098.44.44 0 0 1 .21-.04l.121-.002.479.235c.263.128 1.27.626 2.238 1.106 1.382.685 1.764.868 1.78.853.015-.016-.122-.531-.645-2.428-.622-2.255-.664-2.415-.66-2.52.01-.218-.097-.1 1.884-2.062.986-.976 1.793-1.785 1.793-1.797a.034.034 0 0 0-.02-.03c-.011-.004-.996-.104-2.188-.222a119.37 119.37 0 0 1-2.228-.233.566.566 0 0 1-.237-.176c-.024-.03-.55-1.058-1.167-2.284-.868-1.722-1.13-2.228-1.152-2.228-.022 0-.284.507-1.154 2.236ZM.003 6.172c0 .075.003.106.007.068a.914.914 0 0 0 0-.136C.005 6.068.003 6.1.003 6.173Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default EventS
