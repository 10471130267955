import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_XL } from './constants'

const MonatsblattUnreadXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M41 11c.552 0 1 .448 1 1v24c0 .552-.448 1-1 1H7c-.552 0-1-.448-1-1V12c0-.552.448-1 1-1h34zm-1 3.337L25.974 25.064c-1.11.849-2.63.889-3.779.121l-.17-.121L8 14.338V35h32V14.337zM37.634 13H10.365l13.18 10.078c.238.183.56.203.817.06l.094-.06L37.634 13z"
        transform="translate(-24 -121) translate(24 121)"
      />
    </Svg>
  )
}

export default MonatsblattUnreadXL
