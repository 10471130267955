import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowDownThinS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M11.849.142a.5.5 0 0 1 .01.707l-2.62 2.694-2.621 2.695A.867.867 0 0 1 6 6.5a.857.857 0 0 1-.618-.262L.142.848A.5.5 0 1 1 .858.152L6 5.44 11.142.15a.5.5 0 0 1 .707-.01Z"
      />
    </Svg>
  )
}

export default ArrowDownThinS
