import React, { createContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { SimpleLoading } from 'components/LoadingIcon'
import useRolesViews from 'hooks/useRolesViews'
import { taskStates } from 'pages/Tasks/constants'
import { headerClasses } from 'pages/Tasks/styles'
import BackwardLink from 'components/BackwardLink'
import useDismissTaskNotifications from 'pages/Tasks/hooks/useDismissTaskNotifications'
import useGoBackShiftDate from 'hooks/useGoBackShiftDate'
import { taskQueryAbsences } from '../../queries'
import Content from './Content'
import Accept from './Accept'
import { absenceRequestStatus } from '../constants'

export const DetailsContext = createContext({
  setIsOpenAccept: (_value: boolean) => {},
})

const DetailsPage: React.FC<{ taskUuid: string }> = ({ taskUuid }) => {
  const { t } = useFavurTranslation()
  const { activeView, roles } = useRolesViews()
  const history = useHistory()
  const isOffice = activeView === roles.office
  const [showAcceptPage, setShowAcceptPage] = useState(false)
  const { loading: dismissLoading } = useDismissTaskNotifications({ taskUuid, isManager: isOffice })
  const { gotoBackLink } = useGoBackShiftDate()

  const { data, loading, refetch } = useJamesApolloQuery<{ task: TaskT<AbsenceRequestTaskT> }>(taskQueryAbsences, {
    variables: { favurUuid: taskUuid },
    fetchPolicy: 'no-cache',
    onError() {
      history.push(routes.dashboard)
    },
  })

  const hasFloatingButtons = useMemo(
    () =>
      data?.task.statusFe !== taskStates.closed ||
      (data?.task.status === absenceRequestStatus.approvedOffice && !isOffice),
    [data, isOffice],
  )

  if (!data?.task || dismissLoading) {
    return (
      <Page
        header={<BackwardLink onClickIcon={gotoBackLink} title={t('absenceRequest.title')} />}
        hideNativeNavigation
        hideBackground
        columnCenter
        hideHamburger
        sxHeader={headerClasses.subHeader}
      >
        {(loading || dismissLoading) && <SimpleLoading />}
      </Page>
    )
  }

  if (showAcceptPage) {
    return (
      <Accept
        task={data.task}
        onClickBackIcon={() => {
          setShowAcceptPage(false)
        }}
        onAccept={() => {
          setShowAcceptPage(false)
          refetch()
        }}
      />
    )
  }

  return (
    <DetailsContext.Provider value={{ setIsOpenAccept: setShowAcceptPage }}>
      <Page
        header={<BackwardLink onClickIcon={gotoBackLink} title={t('absenceRequest.title')} />}
        hideNativeNavigation
        hasFloatingButtons={hasFloatingButtons}
        hideHamburger
        hideBackground
        removeLastMargin
        sxHeader={headerClasses.subHeader}
      >
        <Content
          isOffice={isOffice}
          task={data.task as TaskT<AbsenceRequestTaskT>}
          refetchTask={() => {
            refetch()
          }}
          fullScreen
          showAbsencePlannerPreview
          showConflicts={data?.task.statusFe !== taskStates.closed}
        />
      </Page>
    </DetailsContext.Provider>
  )
}

export default DetailsPage
