import React from 'react'
import { Box, IconButton } from '@mui/material'
import Dialog from 'components/Dialog'
import { CloseThinM } from 'icons'
import { CmsContentBlocksT } from 'types'
import palette from 'utils/theme/palette'
import AnnouncementContent from './AnnouncementContent'
import { styles } from './styles'

interface IAnnouncementDialogProps {
  blocks: CmsContentBlocksT[]
  open: boolean
  onClose: () => void
}

const AnnouncementDialog: React.FC<IAnnouncementDialogProps> = ({ blocks, open, onClose }) => {
  return (
    <Dialog sx={styles.dialog} open={open} onClose={onClose}>
      <Box sx={styles.dialogContent}>
        <IconButton onClick={() => onClose()} sx={styles.closeButtonDialog} disableRipple>
          <CloseThinM fill={palette.info.main} />
        </IconButton>
        <AnnouncementContent blocks={blocks} />
      </Box>
    </Dialog>
  )
}

export default AnnouncementDialog
