import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const UpdateS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M3.548 8c0 2.46 1.992 4.452 4.452 4.452 1.181 0 2.254-.46 3.05-1.208l-1.228-1.228c-.244-.244-.071-.661.274-.661h3.517c.214 0 .387.173.387.387v3.517c0 .345-.417.518-.66.274l-1.195-1.195C11.068 13.368 9.608 14 8 14c-3.235 0-5.872-2.56-5.995-5.764L2 8h1.548zM8 2c3.235 0 5.872 2.56 5.995 5.764L14 8h-1.548c0-2.46-1.992-4.452-4.452-4.452-1.181 0-2.254.46-3.05 1.208l1.228 1.228c.244.244.071.661-.274.661H2.387c-.214 0-.387-.173-.387-.387V2.741c0-.345.417-.518.66-.274l1.195 1.195C4.932 2.632 6.392 2 8 2z"
        transform="translate(-329 -458) translate(12 283) translate(0 29) translate(16 124) translate(299 20) translate(2 2)"
      />
    </Svg>
  )
}

export default UpdateS
