import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { ArrowRightS, DocumentPDFL } from 'icons'
import { DocumentContentBlockT } from 'types'
import palette from 'utils/theme/palette'
import { styles } from 'pages/Cms/styles'
import { documentBlockStyles } from '../styles'
import { getFilenameWithoutTimestamp } from '../utils'

interface IDocumentBlockProps {
  data: DocumentContentBlockT
}

const DocumentBlock: React.FC<IDocumentBlockProps> = ({ data }) => (
  <Box component="a" href={data.src} sx={styles.contentCardLink} target="_blank">
    <Card variant="outlined" sx={styles.contentCard}>
      <Box sx={documentBlockStyles.icon}>
        <DocumentPDFL />
      </Box>
      <Box sx={styles.contentCardText}>
        <Typography variant="subtitle1">{getFilenameWithoutTimestamp({ filename: data.filename })}</Typography>
        <Typography variant="caption">{data.filesize}</Typography>
      </Box>
      <ArrowRightS fill={palette.primary.main} />
    </Card>
  </Box>
)

export default DocumentBlock
