import React, { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import BaseInputDrawer from 'components/BaseDrawer/BaseInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import WheelPicker from 'components/WheelPicker'
import WheelPickerColumn from 'components/WheelPicker/WheelPickerColumn'
import { InfoM } from 'icons'
import { throttledRefreshHighSecSession } from 'utils/throttledRefreshHighSecSession'
import ExplanationDrawer from '../ExplanationDrawer'
import { classes } from './styles'

export interface IAddEditDrawerProps {
  open: boolean
  onClose: () => void
  value: number
  onChange: (newValue: number) => void
  onSuccess: () => void
  successButtonDisabled: boolean
}

const ChangeBlockingPeriodDrawer: React.FC<IAddEditDrawerProps> = ({
  open,
  onClose,
  value,
  onChange,
  onSuccess,
  successButtonDisabled,
}) => {
  const [openExplanation, setOpenExplanation] = useState(false)
  const { t } = useFavurTranslation()

  const daysOptions = useMemo(
    () =>
      [...Array(31).keys()].map((option) => ({
        value: option,
        label: t('page.userSettings.tenantReminderList.nDays', { days: option }),
      })),
    [t],
  )

  return (
    <>
      <BaseInputDrawer
        open={open}
        onClose={onClose}
        onSuccess={onSuccess}
        successButtonText={t('page.userSettings.personalData.actions.save')}
        paperSx={classes.paper}
        successButtonDisabled={successButtonDisabled}
      >
        <Box sx={classes.content}>
          <Box sx={classes.textContainer}>
            <Typography variant="subtitle1">{t('page.userSettings.tenant.blockingAbsenceDrawerTitle')}</Typography>
            <Typography variant="body1" sx={classes.textBody}>
              {t('page.userSettings.tenant.blockingAbsenceDrawerBody')}
            </Typography>
            <Button
              color="primary"
              variant="text"
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setOpenExplanation(true)
                throttledRefreshHighSecSession()
              }}
              sx={classes.moreInfoButton}
            >
              {t('page.userSettings.tenantReminderList.moreInfo')}
              <InfoM />
            </Button>
          </Box>
          <WheelPicker>
            <WheelPickerColumn
              options={daysOptions}
              value={value ?? 0}
              onChange={(changeValue) => onChange(changeValue)}
            />
          </WheelPicker>
        </Box>
      </BaseInputDrawer>
      <ExplanationDrawer open={openExplanation} onClose={() => setOpenExplanation(false)} />
    </>
  )
}

export default ChangeBlockingPeriodDrawer
