import React from 'react'
import { useMediaQuery } from '@mui/material'
import theme from 'utils/theme'
import { CmsContentBlocksT } from 'types'
import AnnouncementDialog from './AnnouncementDialog'
import AnnouncementDrawer from './AnnouncementDrawer'

interface IAnnouncementProps {
  blocks: CmsContentBlocksT[]
  open: boolean
  onClose: () => void
}

const Announcement: React.FC<IAnnouncementProps> = ({ blocks, open, onClose }) => {
  const announcementProps = { blocks, open, onClose }
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  if (isSmallScreen) {
    return <AnnouncementDrawer {...announcementProps} />
  }
  return <AnnouncementDialog {...announcementProps} />
}

export default Announcement
