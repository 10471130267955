import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const CalendarTodayS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M9.74 6.8l.82.58c.1.08.14.23.06.35l-2.66 3.71a.25.25 0 0 1-.29.09l-.06-.04L5.4 9.84a.25.25 0 0 1-.05-.35l.6-.8c.07-.1.19-.13.3-.09l.05.04 1.19.89 1.9-2.68a.25.25 0 0 1 .35-.05zM12.5 14h-9a1 1 0 0 1-1-1V4.5a1 1 0 0 1 1-1H5V2h1.5v1.5h3V2H11v1.5h1.5a1 1 0 0 1 1 1V13a1 1 0 0 1-1 1zm-.5-2V6.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5V12c0 .28.22.5.5.5h7a.5.5 0 0 0 .5-.5z"
      />
    </Svg>
  )
}

export default CalendarTodayS
