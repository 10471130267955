import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { BoxedLoading } from 'components/LoadingIcon'
import useShifts from 'hooks/useShifts'
import useSessionContext from 'hooks/useSessionContext'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getDashboardNextShiftDates } from 'utils/shifts'
import { getTaskIds } from 'components/ShiftsList/components/utils'
import NextShift from 'components/NextShift'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { getTasksByDay } from 'pages/Tasks/utils'
import { useEventsDateRange } from 'hooks/useEventsDateRange'
import SectionHeader from '../SectionHeader'
import { classes as dashboardClasses } from '../styles'
import { classes } from './styles'

const NextShiftsSection: React.FC = () => {
  const { t, locale } = useFavurTranslation()
  const { personIds } = useSessionContext()

  const { start, end } = getDashboardNextShiftDates()

  const { eventsByDate } = useEventsDateRange({ startDate: start, endDate: end })

  const { isMultitenant, shifts, loading: loadingShifts } = useShifts({
    dates: { start, end },
    displayFull: true,
    personIds,
  })
  const taskIds = useMemo(() => getTaskIds(shifts), [shifts])
  const { tasks, loading: tasksLoading } = useShiftUpdates({ taskIds })
  const groupedTasks = useMemo(() => getTasksByDay(tasks, locale), [locale, tasks])
  const loading = loadingShifts || tasksLoading

  const firstDayTenants = useMemo(() => {
    if (shifts[0]?.date === start) {
      return shifts[0]?.tenants ?? []
    }

    if (shifts[1]?.date === start) {
      return shifts[1]?.tenants ?? []
    }

    return []
  }, [shifts, start])

  const secondDayTenants = useMemo(() => {
    if (shifts[0]?.date === end) {
      return shifts[0]?.tenants ?? []
    }

    if (shifts[1]?.date === end) {
      return shifts[1]?.tenants ?? []
    }

    return []
  }, [shifts, end])

  if (loading) {
    return <BoxedLoading />
  }

  return (
    <>
      <Box sx={dashboardClasses.section}>
        <SectionHeader title={t('page.dashboard.content.nextShiftHeader')} testId="show-all-shifts" />
        <Box sx={classes.tilesContainer}>
          <NextShift
            date={start}
            shiftsByTenants={firstDayTenants}
            isMultitenant={isMultitenant}
            shiftUpdates={groupedTasks.get(start) ?? []}
            events={eventsByDate.get(start) ?? []}
          />
          <NextShift
            date={end}
            shiftsByTenants={secondDayTenants}
            isMultitenant={isMultitenant}
            shiftUpdates={groupedTasks.get(end) ?? []}
            events={eventsByDate.get(end) ?? []}
          />
        </Box>
      </Box>
    </>
  )
}

export default NextShiftsSection
