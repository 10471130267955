import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowUpThinS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M.151 6.858a.5.5 0 0 1-.01-.707l2.62-2.694L5.383.762A.867.867 0 0 1 6 .5a.857.857 0 0 1 .618.262l5.24 5.39a.5.5 0 0 1-.716.697L6 1.56.858 6.85a.5.5 0 0 1-.707.01Z"
      />
    </Svg>
  )
}

export default ArrowUpThinS
