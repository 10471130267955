import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import useTaskCounts from 'hooks/useTaskCounts'
import { RoleViewT } from 'types'
import SectionHeader from '../SectionHeader'
import { classes } from './styles'
import DashboardTasksFrontliner from './Frontliner'
import DashboardTasksManager from './Manager'

const DashboardTasks: React.FC = () => {
  const { t } = useFavurTranslation()
  const { hasOfficeView: isManager, setActiveView } = useRolesViews()
  const history = useHistory()

  const { taskCounts, loading } = useTaskCounts()

  const onClick = useCallback(
    (role: RoleViewT) => {
      setActiveView(role)
      history.push(`${routes.tasks}/todo`)
    },
    [history, setActiveView],
  )

  return (
    <>
      <SectionHeader title={t('page.tasks.header.openTasks')} testId="show-all-tasks" />
      <Box sx={classes.tilesContainer}>
        {isManager ? (
          <DashboardTasksManager loading={loading} counts={taskCounts} onClick={onClick} />
        ) : (
          <DashboardTasksFrontliner loading={loading} counts={taskCounts} onClick={onClick} />
        )}
      </Box>
    </>
  )
}

export default DashboardTasks
