import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const DocumentPDFL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M28 11.478v13.426c0 1.055-.4 2.086-1.138 2.86A3.953 3.953 0 0 1 24.002 29h-16a3.955 3.955 0 0 1-2.863-1.234A4.154 4.154 0 0 1 4 24.904V8.103c0-.526.098-1.048.29-1.538.194-.491.48-.943.847-1.329a3.996 3.996 0 0 1 1.3-.91A3.918 3.918 0 0 1 8.001 4H20.78c.451 0 .896.093 1.308.272.411.179.778.438 1.083.758l3.885 4.08c.614.644.943 1.497.944 2.368ZM13.35 6H19v3.5a2.5 2.5 0 0 0 2.5 2.5H26v12.902c0 .556-.211 1.09-.586 1.483A1.953 1.953 0 0 1 24 27H8c-.53 0-1.04-.22-1.414-.614A2.154 2.154 0 0 1 6 24.902v-16.8c0-.276.051-.55.152-.805.1-.255.248-.486.433-.682.186-.195.407-.35.65-.455C7.478 6.054 7.738 6 8 6h5.349Zm10.697 4h1.095l-3.418-3.59A1.332 1.332 0 0 0 21 6.019V9.5a.5.5 0 0 0 .5.5h2.547ZM8.562 15a1 1 0 0 0-1.063.998v7.5a1 1 0 1 0 2 0V21.49A3.25 3.25 0 0 0 9.25 15h-.688Zm.937 2.025v2.45a1.25 1.25 0 0 0 0-2.45ZM13.5 16a1 1 0 0 1 1-1 4 4 0 0 1 4 4v1.5a4 4 0 0 1-4 4 1 1 0 0 1-1-1V16Zm2 1.268v4.964a2 2 0 0 0 1-1.732V19a2 2 0 0 0-1-1.732Zm6.146-.122A.5.5 0 0 1 22 17h1.5a1 1 0 1 0 0-2H22a2.5 2.5 0 0 0-2.5 2.5v2.953a1.093 1.093 0 0 0 0 .09V23.5a1 1 0 1 0 2 0v-2.002h1.249a1 1 0 1 0 0-2H21.5V17.5a.5.5 0 0 1 .146-.354Z"
      />
    </Svg>
  )
}

export default DocumentPDFL
