import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { History, historyModificationTypes } from 'types'
import { getAvatarDataFromHistory } from 'utils/avatar'
import Avatar from 'components/Avatar'
import { getContentViewDate } from '../utils'
import { styles } from '../styles'

type HistoryCardProps = {
  history: History
}

const HistoryCard: React.FC<HistoryCardProps> = ({ history }) => {
  const { t, locale } = useFavurTranslation()
  const avatar = getAvatarDataFromHistory(history)
  const timeAt = getContentViewDate({ locale, date: history.insertedAt })

  const translations = useMemo(() => {
    switch (history.modificationType) {
      case historyModificationTypes.createContent:
        return { name: 'page.cms.content.createdBy', date: 'page.cms.content.createdAt' }

      case historyModificationTypes.updateContent:
        return { name: 'page.cms.content.createdBy', date: 'page.cms.content.createdAt' }

      default:
        return { name: '', date: '' }
    }
  }, [history.modificationType])

  if (!avatar) return null

  return (
    <Box sx={styles.historyContainer}>
      <Avatar avatar={avatar} size={40} sx={styles.avatar} />
      <Box sx={styles.historyTextContainer}>
        <Typography variant="body1">
          {`${t(translations.name)} ${history && history.userFirstName} ${history && history.userLastName}.`}
        </Typography>
        <Typography variant="body1" sx={styles.date}>
          {`${t(translations.date)} ${timeAt}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default HistoryCard
