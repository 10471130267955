import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { changeUserLanguageMutation } from 'shared/queries'
import seti18nCookie from 'i18n/setCookie'
import useFavurTranslation from './useFavurTranslation'

// we remove everything behind the original language code. E.g.: en-GB becomes en
const sanitizeLanguage = (language: string) => language.slice(0, 2)

const useUserLanguage = () => {
  const { language, i18n } = useFavurTranslation()
  const [loadingMutation, setLoadingMutation] = useState(false)

  const [onChangeUserLanguage] = useMutation(changeUserLanguageMutation)

  const persistUserLanguage = useCallback(
    (newLanguage: string) => {
      if (newLanguage) {
        setLoadingMutation(true)
        onChangeUserLanguage({
          variables: { newLanguage },
          onCompleted() {
            setLoadingMutation(false)
          },
        })
      }
    },
    [onChangeUserLanguage],
  )

  const setUserLanguage = useCallback(
    (newLanguage: string | null) => {
      if (!newLanguage) {
        persistUserLanguage(language)
        return
      }
      const sanitizedLanguage = sanitizeLanguage(newLanguage)
      if (sanitizedLanguage !== language) {
        persistUserLanguage(sanitizedLanguage)
        i18n.changeLanguage(sanitizedLanguage)
        i18n.reloadResources([sanitizedLanguage])
        seti18nCookie(sanitizedLanguage)
      }
    },
    [i18n, language, persistUserLanguage],
  )

  return { setUserLanguage, loadingMutation }
}

export default useUserLanguage
