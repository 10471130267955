import React from 'react'
import { Box } from '@mui/material'
import useSessionContext from 'hooks/useSessionContext'
import { iconTabsClasses } from './styles'
import ManagerTasksButton from './ManagerTasksButton'
import UserTasksButton from './UserTasksButton'
import FiltersButton from './FiltersButton'
import EventsButton from './EventsButton'
export interface IIconTabsProps {
  isAbsenceManager?: boolean
  taskCountTotal: number
}

const IconTabs: React.FC<IIconTabsProps> = ({ isAbsenceManager, taskCountTotal }) => {
  const { cmsActivated } = useSessionContext()

  return (
    <Box sx={iconTabsClasses.iconContainer}>
      {isAbsenceManager ? (
        <>
          <ManagerTasksButton taskCountTotal={taskCountTotal} />
          <UserTasksButton />
          <FiltersButton />
          {cmsActivated && <EventsButton />}
        </>
      ) : (
        <>
          <FiltersButton />
          <UserTasksButton />
          {cmsActivated && <EventsButton />}
        </>
      )}
    </Box>
  )
}

export default IconTabs
